export enum OrderStatus {
  AwaitingPayment = "AwaitingPayment",
  FailedPayment = "FailedPayment",
  Paid = "Paid",
  MedProcessing = "MedProcessing",
  ReadyForDelivery = "ReadyForDelivery",
  Delivery = "Delivery",
  Complete = "Complete",
  Cancelled = "Cancelled",
}
