import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { ordersStore } from '../../store';
import { OrderStatus } from '../../types/enums/orders';
import './index.css';
interface FormSectionsProps {
  section: CheckoutSection;
  step: number;
  maxStep: number;
  isMobile: boolean;
  personalInfo: Personal;
  data: {
    title: string;
    name: string;
  }[];
  currentStepSectionNumber: number;
  onChangeStep: (section: string) => void;
}

const FormSections: React.FC<FormSectionsProps> = observer((props) => {
  const { section, children, step, maxStep, isMobile, personalInfo } = props;

  const { order } = ordersStore;
  const isTitleHidden = section.name === 'result' && order !== null && order.status !== OrderStatus.ReadyForDelivery;

  const confirmationPage = section.name === 'health' && step === maxStep;
  const sectionTitle = useMemo(() => {
    if (confirmationPage) {
      return 'Подтверждение анкеты';
    }
    let title = isMobile && section.mobileTitle ? section.mobileTitle : section.title;
    if (typeof title === 'function') {
      return title({ user: personalInfo, step });
    }
    return title;
  }, [confirmationPage, section, step, isMobile]);

  const sectionDescription = useMemo(() => {
    if (confirmationPage) {
      return 'Проверьте еще раз и мы начнем обрабатывать данные';
    }
    let description = isMobile && section.mobileDescription ? section.mobileDescription : section.description;
    if (typeof description === 'function') {
      return description({ user: personalInfo, step });
    }
    return description;
  }, [confirmationPage, section, step, isMobile]);

  return (
    <div className="FormSection">
      <div className="FormSection-Wrapper">
        {/* {isMobile && (
          <MobileSteps
            step={step}
            data={data}
            currentStepSectionNumber={currentStepSectionNumber}
            onChangeStep={onChangeStep}
          />
        )} */}
        {!isTitleHidden && (
          <>
            <div className="FormSection-Title">
              <p className="subtitle-secondary">{sectionTitle} </p>
              {section.name === 'health' && step < maxStep && (
                <div className="FormSection-Label">
                  {step + 1}/{maxStep} Заполнено
                </div>
              )}
            </div>
            {(!isMobile || section.mobileDescription) && (
              <p className={`FormSection-Description paragraph`}>
                {sectionDescription}
              </p>
            )}
          </>)}
        <div className="FormSection-Content">{children}</div>
      </div>
    </div>
  );
});

export default FormSections;
