import * as React from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps as AntdModalProps } from 'antd/lib/modal';

import './index.css';

interface ModalProps extends AntdModalProps {
  footer?: JSX.Element[];
  onClose: () => void;
  adjustWidth?: boolean;
}

const Modal: React.FunctionComponent<ModalProps> = (props) => {
  const { footer = null, className = '', title, visible, closable = true, onClose, adjustWidth } = props;

  const styles = {
    '--image-close': `url(/images/close.svg)`,
  } as React.CSSProperties;

  const minWidth = 632;
  const windowWidth = window.innerWidth / 2.3 < minWidth ? minWidth : Math.ceil(window.innerWidth / 2.3);
  return (
    <AntdModal
      title={title}
      width={adjustWidth ? windowWidth : minWidth}
      footer={footer}
      visible={visible}
      className={`${className} Modal`}
      onCancel={onClose}
      closable={closable}
      style={styles}
    >
      {props.children}
    </AntdModal>
  );
};

export default Modal;
