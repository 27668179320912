import * as React from 'react';
import { Badge } from 'antd';
import './index.css';

interface ButtonProps {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseOut?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseOver?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  close?: boolean;
  back?: boolean;
  form?: boolean;
  small?: boolean;
  secondary?: boolean;
  info?: boolean;
  filter?: boolean;
  filterApplied?: boolean;
  black?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    disabled = false,
    onClick,
    onMouseOut,
    onMouseOver,
    children,
    close,
    back,
    form,
    secondary,
    small,
    info,
    filter,
    filterApplied,
    black,
  } = props;
  const className = `btn ${form ? 'btn--primary' : ''} ${close ? 'btn--close' : ''} ${back ? 'btn--back' : ''} ${
    secondary ? 'btn--secondary' : ''
  } ${small ? 'btn--small' : ''}  ${info ? 'btn--info' : ''} ${filter ? 'btn--filter' : ''} ${
    black ? 'btn--black' : ''
  }`;

  const buttonStyles = {
    '--image-back': `url(/images/arrow-back.svg)`,
    '--image-close': `url(/images/close.svg)`,
    '--image-info': `url(/images/info.svg)`,
    '--image-filter': `url(/images/filter.svg)`,
  } as React.CSSProperties;

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      style={buttonStyles}
    >
      {children}
      {filterApplied && <Badge dot />}
    </button>
  );
};

export default Button;
