import { genderOptions } from '../components/HealthForm';
import {
  emailRegExp,
  dateRegExp,
  rusRegExp,
  rusIncludeDigitsRegExp,
  rusEngRegExp,
  zipRegExp,
  isPhoneValid,
} from './index';

export const checkFields = (
  personalInfo: Personal,
  authCode: string,
  healthInfo: HealthForm,
  addressInfo: Address,
  passportData: Passport,
  section: string,
  allowUsePersonalData: boolean,
  step: number
): boolean => {
  const { givenName, familyName, email, phone, fatherName } = personalInfo;
  const {
    birthDate,
    gender,
    vits,
    vitsQuantity,
    height,
    weight,
    lifestyle,
    allergy,
    sickness,
    recs,
    meds,
    goals,
    diet,
    pregnancy,
  } = healthInfo;

  let isValid = false;

  const validGender =
    gender === genderOptions[0].answerPositionId ||
    (gender === genderOptions[1].answerPositionId && pregnancy !== '');

  const validPersonal =
    rusRegExp.test(givenName) &&
    rusRegExp.test(familyName) &&
    rusRegExp.test(fatherName) &&
    email.match(emailRegExp) &&
    isPhoneValid(phone) &&
    allowUsePersonalData === true;

  const validHealthStep0 =
    vits !== '' &&
    (vits === '5f07173c-c2e9-4cc9-9653-f952896af545' || vitsQuantity !== '');

  const validHealthStep1 =
    validGender &&
    height !== '' &&
    weight !== '' &&
    dateRegExp.test(birthDate);

  if (section === 'pay') {
    isValid = true;
  }

  if (section === 'personal') {
    isValid = validPersonal === true;
  }

  if (section === 'health') {
    if (
      (step === 0 && validHealthStep0) ||
      (step === 1 && validHealthStep1) ||
      (step === 2 && lifestyle !== '') ||
      (step === 3 && allergy.length) ||
      (step === 4 && sickness.length) ||
      (step === 5 && recs.length) ||
      (step === 6 && meds.length) ||
      (step === 7 && goals.length) ||
      (step === 8 && diet !== '') ||
      step === 9
    ) {
      isValid = true;
    }
  }

  if (section === 'result') {
    return true;
  }

  if (section === 'delivery') {
    const { country, city, addressLine, zip } = addressInfo;
    const { number, issueDate, issuedBy } = passportData;
    const validAddress =
      rusEngRegExp.test(country) &&
      rusEngRegExp.test(city) &&
      addressLine.length > 2 &&
      zipRegExp.test(zip) &&
      zip.length > 5;

    const validPassport =
      number.trim().length === 11 &&
      issueDate.trim().length === 10 &&
      rusIncludeDigitsRegExp.test(issuedBy);

    if (validAddress && validPassport) {
      isValid = true;
    }
  }

  if (section === 'auth') {
    if (authCode.length === 4) {
      return true;
    }
  }

  return isValid;
};
