import React from 'react';
import './log';

// eslint-disable-next-line max-len
export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegExp = /^79(\d){9}$/;
export const isPhoneValid = (phone: string) => phoneRegExp.test(phone.replace(/\D/g, ''));
export const dateRegExp = /^(?:0[1-9]|[12]\d|3[01])([.])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/;
export const rusRegExp = /^[а-яА-ЯёЁ -]+$/;
export const rusEngRegExp = /^[а-яА-ЯёЁa-zA-z -]+$/;
export const rusIncludeDigitsRegExp = /^[0-9а-яА-ЯёЁ -.]+$/;
export const zipRegExp = /^[0-9a-zA-Z -]+$/;

export const MOSCOW_CITY_ID = '8112839c-99ca-48da-8c20-98aae930a278'; // москва
export const MO_CITY_ID = '1a06e18c-49b7-4133-8b26-01ecd5791de6'; // московская область
export const SPB_CITY_ID = '22821ab3-82b1-4f1f-8fed-83e488976990'; // санкт-петербург

export enum Product {
  BalanceTest = 'balance-test',
  Check = 'check',
  VitD = 'vit-d',
  Balance = 'balance',
  Go = 'go',
}

export enum Vendor {
  Kdl = 'Kdl',
  Invitro = 'Invitro',
  Medsi = 'Medsi',
  Bioniq = 'Bioniq',
  Chromolab = 'Chromolab',
}

export const questions = {
  vits: 'aaece77e-0f0c-4f2d-9888-05724c187544', // 0
  vitsQuantity: '07c167d9-1faa-4fdc-b3b0-f8bf07a49650', // 0
  height: '594f8823-4036-44ea-86ec-5a8a48bc907c', // 1
  weight: 'b452296d-a047-434f-9bf1-b802723d18d1', // 1
  lifestyle: '58a967d2-2412-49c4-91a7-968e3b747ad0', // 2
  allergy: '1980b1fc-c872-40cc-9e1b-01b2dca40c66', // 3
  sickness: '6e913fd0-8c1d-460b-98fe-7284a4b80bd4', // 4
  recs: 'bd7178af-6575-4ac4-8dde-81febc2d2e27', // 5
  meds: 'd18f32ea-dcf1-4237-8aff-faec118db279', // 6
  goals: '_bd7178af-6575-4ac4-8dde-81febc2d2e27', // 7
  diet: 'ad4cdb0b-ed94-4f2d-93a8-4e3bf5158ef2', // 8
  pregnancy: 'a7b73594-f0be-40d6-b951-03b000215e20',
  // + доп информация
  // + согласие на обработку перс. данных
};

export enum Gender {
  MALE = '388d85f2-3016-41d3-a7cf-024ba1b3e16d',
  FEMALE = 'ae2015ac-1262-4742-b2b0-f2b15c5b75e8',
}

// export const defaultQuestionsAnswers = {
//   '7bbbdc2e-b60c-4345-86ee-0bfbd21b9378': '3f064cd3-7e10-477f-a7f1-18031926d487', // подтверждаю что нет заболеваний
//   '87a66d73-5ab1-493c-9c32-5ed001900b2e': '8863a876-7cf5-4ad5-b687-23918d202671', // нужно согласование с врачем
// };

export const formatJSON = (json: Question[]): Questionaire => {
  const questionary: Questionaire = {
    vits: json.find((el) => el.questionPositionId === questions.vits)!,
    vitsQuantity: json.find((el) => el.questionPositionId === questions.vitsQuantity)!,
    height: json.find((el) => el.questionPositionId === questions.height)!,
    weight: json.find((el) => el.questionPositionId === questions.weight)!,
    lifestyle: json.find((el) => el.questionPositionId === questions.lifestyle)!,
    allergy: json.find((el) => el.questionPositionId === questions.allergy)!,
    sickness: json.find((el) => el.questionPositionId === questions.sickness)!,
    recs: json.find((el) => el.questionPositionId === questions.recs)!,
    meds: json.find((el) => el.questionPositionId === questions.meds)!,
    goals: json.find((el) => el.questionPositionId === questions.goals)!,
    diet: json.find((el) => el.questionPositionId === questions.diet)!,
    pregnancy: json.find((el) => el.questionPositionId === questions.pregnancy)!,
    // contraindications: json.find((el) => el.questionPositionId === questions.contraindications)!,
  };

  return questionary;
};

export const throttle = (callback: Function, limit: number): (() => void) => {
  let waiting = false;
  return (...args: any): void => {
    if (!waiting) {
      callback.apply(this, args);
      waiting = true;
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  };
};

export function getLabOfficeSteps(vendor: VendorType, productName: Product): string[] {
  const days = productName === Product.BalanceTest ? 10 : 5;
  const step2 = `Результаты анализа крови будут доступны через ${days} рабочих дней после забора крови, об этом мы уведомим вас в СМС-сообщении и по электронной почте.`;
  const invitro =
    'В медицинском офисе на стойке регистрации предъявите паспорт и сообщите, что вы корпоративный клиент bioniq.';
  const kdl = `В медицинском офисе на стойке регистрации предьявите паспорт и номер заказа `;
  const other = `В медицинском офисе на стойке регистрации предъявите паспорт и сообщите, что Вы пришли сдавать bioniq ${productName.replace(
    /-/g,
    ' '
  )}`;

  const step1 = vendor === Vendor.Kdl ? kdl : vendor === Vendor.Invitro ? invitro : other;
  return [step1, step2];
}

export function getMedsiWarning(productName: Product): string {
  return productName === Product.Check
    ? 'Обращаем Ваше внимание, что клиники Медси принимают только пациентов старше 18 лет. Пожалуйста, выберите другую клинику.'
    : 'Ранее Вы выбрали клинику MEDCИ, которая принимает пациентов только старше 18 лет. Пожалуйста, выберите другую клинику.';
}

export function humanizePhone(phone: string): string {
  const country = phone.slice(0, 2);
  const operator = phone.slice(2, 5);
  const number = [phone.slice(5, 8), phone.slice(8, 10), phone.slice(10, 12)];
  return `${country} (${operator}) ${number[0]}-${number[1]}-${number[2]}`;
}
export function dehumanizePhone(phone: string): string {
  return phone.replace(/[()]|-|\s/g, '');
}

export function humanizeDate(raw: string | Date): string {
  const date = typeof raw === 'string' ? new Date(raw) : raw;
  const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
  return [day.toString().padStart(2, '0'), (month + 1).toString().padStart(2, '0'), year.toString()].join('.');
}

export function humanDateToISO(raw: string): string {
  const [d, m, y] = raw.split('.');
  return [y, m, d].join('-');
}

export function getCheckoutSections(sections: string[]): CheckoutSection[] {
  return sections.map((section) => checkoutSections()[section]);
}

interface SectionText {
  user: User;
  step: number;
}
export const checkoutSections = (): Record<string, CheckoutSection> => {
  const personal = {
    title: 'Приятно познакомиться!',
    description: 'Зарегистрируйтесь для оплаты и отслеживания статуса заказа',
    mobileTitle: 'Регистрация',
    mobileDescription: 'Для оплаты и отслеживания статуса заказа',
    name: 'personal',
    step: 'Регистрация',
  };
  const auth = {
    title: 'Проверяем телефон',
    name: 'auth',
    description: ({ user }: SectionText) => <><b>{user?.givenName}</b>, введите код, направленный вам на номер <b>{user?.phone}</b></>,
    mobileDescription: ({ user }: SectionText) => <><b>{user?.givenName}</b>, введите код, направленный вам на номер <b>{user?.phone}</b></>,
    step: 'Регистрация',
  };
  const health = {
    title: ({ user, step }: SectionText) => step === 0 ? `${user.givenName}, спасибо за покупку` : 'Анкета',
    name: 'health',
    description: ({ step }: SectionText) => step === 0 ? 'Пожалуйста, заполните анкету: это  займет несколько минут' : 'Ответьте на вопросы анкеты как можно точнее: это необходимо для определения персонализированных дозировок',
    step: 'Анкета',
  };
  const delivery = {
    title: ({ user }: SectionText) => user?.givenName ? <><b>{user.givenName}</b>, спасибо за покупку!</> : <>Спасибо за покупку!</>,
    name: 'delivery',
    description: 'Ваш адрес и паспортные данные необходимы для осуществления доставки комплекса из Великобритании',
    step: 'Доставка',
  };
  const result = {
    title: 'Дозировки специально для вас',
    name: 'result',
    description: 'По данному рецепту мы изготовим ваш персонализированный комплекс. Напишите, куда его доставить',
    step: 'Дозировка',
  };
  const pay = {
    title: 'Оплата заказа',
    name: 'pay',
    description: 'После оплаты вы заполните анкету, мы определим дозировки и привезем ваш персонализированный комплекс через 7-10 дней из Лондона',
    mobileDescription: 'После оплаты вы заполните анкету, мы определим дозировки и привезем ваш персонализированный комплекс через 7-10 дней из Лондона',
    step: 'Оплата',
  };
  return { personal, health, auth, delivery, pay, result };
};

export function getSectionByPath(path: string): CheckoutSection {
  const normPath = path.replace(/\//g, '');

  if (Object.prototype.hasOwnProperty.call(checkoutSections(), normPath)) {
    return checkoutSections()[normPath];
  }
  const firstSection = checkoutSections().personal;
  // console.log(`Can't find section ${normPath}, full path: ${path}`);
  return firstSection;
}

export const toCamelCase = (str: string): string =>
  str.replace(/(_\w)/g, function (k) {
    return k[1].toUpperCase();
  });

export const toSnakeCase = (str: string): string => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
