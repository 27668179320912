import React, { useEffect } from 'react';
import FormInput from '../FormInput';
import { Form } from 'antd';
import { isPhoneValid, rusRegExp } from '../../utils';
import Checkbox from '../Checkbox';
import './index.css';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../store';

interface CheckoutPersonalProps {
  data: { givenName: string; familyName: string; fatherName: string; phone: string; email: string; };
  handleChange: (e: string, name: string) => void;
  currentSection: CheckoutSection;
}

const CheckoutPersonal: React.FC<CheckoutPersonalProps> = observer((props) => {
  const { email, phone, givenName, familyName, fatherName, } = props.data;

  const { allowUsePersonalData } = userStore;
  const handleCheckboxClick = (value: boolean) => {
    userStore.setAllowUsePersonalData(value);
  }

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.data);
  }, [form, props.data]);

  return (
    <div className="CheckoutPersonal">
      <Form form={form} validateTrigger={['onBlur', 'onMouseOut']}>
        <FormInput
          autofocus
          placeholder="Фамилия"
          name="familyName"
          value={familyName}
          onChange={(e: string): void => {
            props.handleChange(e.trim(), 'familyName');
          }}
          rules={[
            {
              required: true,
              min: 2,
              message: 'Введите корректную фамилию',
            },
            {
              message: 'Введите фамилию на русском языке',
              validator: (_, value: string): Promise<void> => {
                if (value.length < 2) {
                  return Promise.resolve();
                } else {
                  return rusRegExp.test(value) ? Promise.resolve() : Promise.reject();
                }
              },
            },
          ]}
        />
        <FormInput
          placeholder="Имя"
          name="givenName"
          value={givenName}
          normalize={(value: string): string => {
            return value.charAt(0).toUpperCase() + value.slice(1);
          }}
          onChange={(e: string): void => {
            props.handleChange(e.trim(), 'givenName');
          }}
          rules={[
            {
              required: true,
              min: 2,
              message: 'Введите корректное имя',
            },
            {
              message: 'Введите имя на русском языке',
              validator: (_, value: string): Promise<void> => {
                if (value.length < 2) {
                  return Promise.resolve();
                } else {
                  return rusRegExp.test(value) ? Promise.resolve() : Promise.reject();
                }
              },
            },
          ]}
        />
        <FormInput
          placeholder="Отчество"
          value={fatherName}
          name="fatherName"
          onChange={(e: string): void => {
            props.handleChange(e.trim(), 'fatherName');
          }}
          rules={[
            {
              required: true,
              min: 2,
              message: 'Введите корректное отчество (напишите нет, если оно отсутствует)',
            },
            {
              message: 'Введите отчество на русском языке',
              validator: (_, value: string): Promise<void> => {
                if (value.length === 0) {
                  return Promise.resolve();
                } else {
                  return rusRegExp.test(value) ? Promise.resolve() : Promise.reject();
                }
              },
            },
          ]}
        />
        <FormInput
          placeholder="Номер телефона"
          name="phone"
          value={phone}
          mask="+7 (111) 111-11-11"
          inputMode="numeric"
          onChange={(e: string): void => {
            props.handleChange(e, 'phone');
          }}
          rules={[
            {
              required: true,
              len: 18,
              message: 'Введите корректный номер телефона',
              validator: (_, value: string): Promise<void> => {
                return isPhoneValid(value) ? Promise.resolve() : Promise.reject();
              },
            },
          ]}
        />
        <FormInput
          type="email"
          placeholder="Электронная почта"
          name="email"
          inputMode="email"
          value={email}
          onChange={(e: string): void => {
            props.handleChange(e.toLowerCase(), 'email');
          }}
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Введите корректный e-mail',
            },
          ]}
        />
        {props.currentSection.name === 'personal' &&
          <Checkbox checked={allowUsePersonalData} onClick={handleCheckboxClick} label={(
            <p>Я выражаю согласие с условиями <a href="https://assets.website-files.com/60fe0162ef262b855a8e0d9e/60fe0162ef262b03418e0fb2_TermsConditions%20-%202021_April%20-%20RU.pdf" target="_blank" rel="noreferrer">пользовательного соглашения</a> и даю согласие на <a href="https://bioniq.com/ru/privacy" target="_blank" rel="noreferrer">обработку персональных данных</a></p>
          )} />
        }
      </Form>
    </div>
  );
});

export default CheckoutPersonal;
