import config from "../utils/config";
import { postRequest, request } from "./common";

export class OrdersApi {
  static baseUrl = config.PRODUCTS_API;

  static getOrders(): Promise<IOrder> {
    return request('/shop/go/orders');
  }

  static getRecipes(): Promise<any> {
    return request('/shop/go/recipes');
  }

  static addHealthData(orderId: string, data: AddHealthFormRequest) {
    return postRequest(`/shop/go/${orderId}/add-health-form`, data);
  }

  static addDeliveryData(orderId: string, data: AddDeliveryFormRequest) {
    return postRequest(`/shop/go/${orderId}/add-delivery-form`, data);
  }
}
