import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import config from '../../utils/config';
import { userStore } from '../../store';
import './index.css';
import { useHistory } from 'react-router';

interface HeaderProps {
  orderStatus?: boolean;
}

const Header: React.FC<HeaderProps> = observer(({ orderStatus }) => {
  const history = useHistory();
  const { user } = userStore;
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const handleLogout = () => {
    userStore.logout();
    setIsLogoutVisible(false);
    history.push('/login');
  }

  useEffect(() => {
    const handleClick = () => {
      if (isLogoutVisible) {
        setIsLogoutVisible(false);
      }
    }
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [isLogoutVisible]);

  const img = orderStatus ? '/images/bioniq-go-logo-white.svg' : '/images/bioniq-go-logo-black.svg';
  return (
    <div className={`Header-Container ${orderStatus ? 'Header--StatusPage' : ''}`}>
      <div className="Header">
        <div className="BioniqLogo">
          <img src={img} alt="Bioniq" />
          {/* <p className="BioniqLogo-ProductName">GO</p> */}
        </div>
        {config.ENV === 'DEV' && <span className="Header-Dev">DEV</span>}
        {user !== null && (
          <div className="HeaderUser-Wrapper">
            <div className="HeaderUser" onClick={() => setIsLogoutVisible(true)}>
              {[user.givenName, user.familyName].join(' ')}
            </div>
            {isLogoutVisible && (
              <div className="HeaderUserLogout">
                <a href="#logout" onClick={(e) => [e.preventDefault(), handleLogout()]} className="HeaderUserLogout-Link">Выйти</a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default Header;
