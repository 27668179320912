import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useHistory, Switch, Route } from 'react-router-dom';
import CheckoutPay from '../CheckoutPay';
import FormSections from '../Form';
import Steps from '../Steps';
// import { purchase, purchaseWithApplePay, purchaseWithGooglePay } from '../../utils/payment';
import { getSectionByPath, getCheckoutSections, Product } from '../../utils';
import { emptyPersonalInfo, emptyHealthInfo } from '../../utils/emptyInfo';
import { checkFields } from '../../utils/fieldsValidation';
import CheckoutButtons from '../CheckoutButtons';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import CheckoutPersonal from '../CheckoutPersonal';
import HealthForm from '../HealthForm';
import { saveUserContacts } from '../../api/leads';
import { pushToDataLayer, UtmMarks } from '../../utils/analytics';
import { ToastContainer } from 'react-toastify';
import { deliveryStore, ordersStore, userStore } from '../../store';
import { Storage } from '../../utils/storage';
import { observer } from 'mobx-react-lite';
import Delivery from '../Delivery';
import Result from '../Result';
import CheckoutAuth from '../CheckoutAuth';
import useInit from './useInit';
// import CheckoutStart from '../CheckoutStart';

interface CheckoutProps {
  sections: string[];
}
const Checkout: React.FC<CheckoutProps> = observer(({ sections }) => {
  const checkoutSections = getCheckoutSections(sections);
  const [step, setStep] = useState(0);
  const [isDisabled, setDisabled] = useState(true);
  const [personalInfo, setPersonalInfo] = useState<Personal>(emptyPersonalInfo);
  const [authCode, setAuthCode] = useState('');
  const [healthInfo, setHealthInfo] = useState<HealthForm>(emptyHealthInfo);
  const [isMobile, setIsMobileVersion] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [isError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { order, recipe } = ordersStore;

  useInit({
    isLoading: userStore.isLoading || ordersStore.isLoading,
    isAuthorized: userStore.isAuthorized,
    order,
    recipe,
  })

  const location = useLocation();
  const history = useHistory();
  const [currentSection, setCurrentSection] = useState(
    getSectionByPath(location.pathname)
  );

  useEffect(() => {
    UtmMarks.parseFromUrl();

    const personal = Storage.read('personalInfo');
    if (personal != null) {
      setPersonalInfo(personal);
    }

    const health = Storage.read('healthInfo');
    if (health !== null) {
      setHealthInfo(health);
    }
  }, []);

  useEffect(() => {
    setCurrentSection(getSectionByPath(location.pathname));
  }, [location]);

  useEffect(() => {
    validateForm();
  });

  useEffect(() => {
    function handleResize(): void {
      setIsMobileVersion(window.innerWidth < 560);
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePersonalInfoChange = (value: string, name: string): void => {
    setPersonalInfo({ ...personalInfo, [name]: value });
  };
  const handleHealthInfoChange = (value: string | string[], name: string): void => {
    setHealthInfo({ ...healthInfo, [name]: value });
  };

  const MAX_STEP = 9;

  const handlePrevClick = (): void => {
    if (currentSection.name === 'health' && step > 0) {
      setStep(step - 1);

      validateForm();
    } else {
      const index = sections.findIndex((el) => el === currentSection.name);
      if (index > 0) {
        history.push(sections[index - 1]);
      }
    }
  };

  const validateForm = useCallback((): void => {
    const fieldsValid = checkFields(
      personalInfo,
      authCode,
      healthInfo,
      deliveryStore.addressInfo,
      deliveryStore.passportData,
      currentSection.name,
      userStore.allowUsePersonalData,
      step
    );

    setDisabled(step > MAX_STEP ? true : !fieldsValid);
  }, [
    authCode,
    currentSection.name,
    healthInfo,
    personalInfo,
    step,
    userStore.allowUsePersonalData,
    deliveryStore.addressInfo,
    deliveryStore.passportData,
  ]);

  const handleNextClick = async (e?: React.SyntheticEvent | string): Promise<void> => {
    if (e && typeof e === 'object') {
      e.preventDefault();
    }
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (currentSection.name === 'personal') {
      Storage.write('personalInfo', personalInfo);
    } else if (currentSection.name === 'delivery') {
      deliveryStore.save();
    } else {
      Storage.write('healthInfo', healthInfo);
    }

    if (currentSection.name === 'personal') {
      try {
        saveUserContacts({ ...personalInfo, product: Product.Go });
        await userStore.signup(personalInfo);
      } catch (err) {
        console.log('auth err', err);
        setIsLoading(false);
        return;
      }
    }

    if (currentSection.name === 'health' && step < MAX_STEP) {
      setStep(step + 1);
      window.scroll(0, 0);
    } else if (currentSection.name === 'health' && step === MAX_STEP) {
      await ordersStore.addHealthData(healthInfo);
      await ordersStore.getOrders();
      history.push('/result');
    } else if (currentSection.name === 'auth') {
      try {
        setIsAuthError(false);
        await userStore.verify(personalInfo.phone, typeof e === 'string' ? e : authCode);
        await ordersStore.getOrders();
        await ordersStore.getRecipe();
        history.push('/pay');
      } catch (err) {
        console.log('auth verify err', err);
        setIsAuthError(true);
      }
    } else if (currentSection.name === 'delivery') {
      try {
        await ordersStore.addDeliveryData(deliveryStore.addressInfo, deliveryStore.passportData);
        history.push('/status');
      } catch (err) {

      }
    } else {
      const index = sections.findIndex((el) => el === currentSection.name);
      if (index < sections.length - 1) {
        history.push(sections[index + 1]);
      }
    }
    setIsLoading(false);
    pushToDataLayer(currentSection.name, step);
  };

  const handlePayClick = async (): Promise<void> => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const result = await (window as any).handlePay();
    if (result) {
      console.log('pay function done');
      await ordersStore.getOrders();
      history.push('/health');
    }
    setIsLoading(false);
  };

  const currentStepSectionNumber = useMemo((): number => sections.indexOf(currentSection.name), [
    currentSection.name,
    sections,
  ]);

  return (
    <div className="Checkout">
      <aside className="Checkout-Aside">
        <div className="Checkout-AsideContent">
          <p className="subtitle">Оформление заказа</p>
          <p className="paragraph">Самый удобный способ принимать необходимые витамины</p>
          <Steps
            step={currentStepSectionNumber}
            data={checkoutSections.map((el) => ({ title: el.step, name: el.name }))}
            onChangeStep={(section: string): void => history.push(section)}
          />
        </div>
      </aside>
      <main className="Checkout-Main">
        <div className="Checkout-MainContent">
          <div className="Checkout-MainSection">
            <FormSections 
              section={currentSection}
              step={step} 
              maxStep={MAX_STEP} 
              isMobile={isMobile} 
              personalInfo={personalInfo}
              data={checkoutSections.map((el) => ({ title: el.step, name: el.name }))}
              currentStepSectionNumber={currentStepSectionNumber}
              onChangeStep={(section: string): void => history.push(section)}
            >
              <Switch>
                <Route path="/auth">
                  <CheckoutAuth
                    isError={isAuthError}
                    handleChange={setAuthCode}
                    handleSubmit={handleNextClick}
                  />
                </Route>
                <Route path="/health">
                  <HealthForm
                    data={healthInfo}
                    key="health"
                    isLoading={isLoading}
                    step={step}
                    setStep={(num: number): void => {
                      setStep(num);
                    }}
                    handleChange={handleHealthInfoChange}
                  />
                </Route>
                <Route path="/delivery">
                  <Delivery />
                </Route>
                <Route path="/result">
                  <Result />
                </Route>
                <Route path="/pay">
                  <CheckoutPay
                    key="pay"
                    isError={isError}
                    validateForm={validateForm}
                  />
                </Route>
                <Route path="/">
                  <CheckoutPersonal
                    currentSection={currentSection}
                    data={personalInfo}
                    handleChange={handlePersonalInfoChange}
                  />
                </Route>
              </Switch>
            </FormSections>
            <CheckoutButtons
              currentSection={currentSection}
              isDisabled={isDisabled || isLoading}
              step={step}
              isMobile={isMobile}
              handleNextClick={handleNextClick}
              handlePrevClick={handlePrevClick}
              handlePayClick={handlePayClick}
            />
          </div>
        </div>
      </main>
      <ToastContainer
        position={window.innerWidth < 840 ? 'top-center' : 'bottom-center'}
        hideProgressBar
        closeButton={false}
      />
    </div>
  );
});

export default Checkout;
