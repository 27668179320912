import * as React from 'react';
import { Steps as AntdSteps } from 'antd';
import './index.css';

const { Step } = AntdSteps;
interface StepsProps {
  step: number;
  data: StepItem[];
  className?: string;
  onChangeStep: (section: string) => void;
}
interface StepItem {
  title: string;
  name: string;
}

const Steps: React.FunctionComponent<StepsProps> = (props) => {
  const { step, data, className = '' } = props;

  const handleClick = (index: number): void => {
    // if (step < index) {
    //   return;
    // } else {
    //   props.onChangeStep(data[index].name);
    // }
  };

  const stepsStyles = { '--image-checked': `url(/images/checked.svg)` } as React.CSSProperties;
  return (
    <div className="step-container" style={stepsStyles}>
      <AntdSteps className={`Steps ${className}`} direction="vertical" size="small" current={step}>
        {data.map((el, index) => {
          return <Step key={el.title} title={el.title} onClick={handleClick.bind(null, index)} />;
        })}
      </AntdSteps>
    </div>
  );
};

export default Steps;

interface MobileStepsProps {
  step: number;
  data: StepItem[];
  currentStepSectionNumber: number;
  onChangeStep: (section: string) => void;
}

export const MobileSteps: React.FC<MobileStepsProps> = ({ step, currentStepSectionNumber, data, onChangeStep }) => {
  const handleClick = (index: number): void => {
    // if (step < index) {
    //   return;
    // } else {
    //   onChangeStep(data[index].name);
    // }
  };

  return (
    <div className="MobileSteps">
      <p className="subtitle">{data[currentStepSectionNumber].title}</p>
      {data.map((el, index) => {
        return (
          <span
            key={el.name}
            onClick={handleClick.bind(null, index)}
            className={
              currentStepSectionNumber === index
                ? 'Current-Step'
                : currentStepSectionNumber > index
                ? 'Completed-Step'
                : ''
            }
          >
            Шаг {index}
          </span>
        );
      })}
    </div>
  );
};
