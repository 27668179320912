import React from 'react';
import { genderOptions } from '../HealthForm';
import './index.css';

interface CheckoutConfirmProps {
  data: HealthForm;
  questions: Questionaire;

  setStep: (num: number) => void;
}

const CheckoutConfirm: React.FunctionComponent<CheckoutConfirmProps> = ({ data, setStep, questions }) => {
  const fields: QuestionTitle[] = ['vits', 'vitsQuantity', 'lifestyle', 'allergy', 'sickness', 'recs', 'meds', 'goals', 'diet',];
  
  const findAnswer = (question: Question, answer: string | string[]) => {
    const answers = [];

    if (Array.isArray(answer)) {
      answer.forEach((a) => {
        const found = question.answers.find((el) => el.answerPositionId === a);
        answers.push(a === 'nothing' ? 'Ничего из вышеперечисленного' : found?.text);
      });
    } else {
      answers.push(question.answers.find((el) => el.answerPositionId === answer)?.text);
    }
    return answers;
  };

  return (
    <div className="CheckoutConfirm">
      <div className="ConfirmField">
        <div>
          <p className="ConfirmField-Title"> Дата рождения</p>
          <p className="body"> {data.birthDate}</p>
        </div>
        <p className="ConfirmField-Edit" onClick={(): void => setStep(1)}>
          Редактировать
        </p>
      </div>

      <div className="ConfirmField">
        <div>
          <p className="ConfirmField-Title"> Ваш пол</p>
          {genderOptions.find((el) => el.answerPositionId === data.gender)!.text}
        </div>
        <p className="ConfirmField-Edit" onClick={(): void => setStep(1)}>
          Редактировать
        </p>
      </div>

      {data.gender === genderOptions[1].answerPositionId && (
        <div className="ConfirmField">
          <div>
            <p className="ConfirmField-Title"> {questions.pregnancy.text}</p>
            {questions.pregnancy.answers.find((el) => el.answerPositionId === data.pregnancy)!.text}
          </div>
          <p className="ConfirmField-Edit" onClick={(): void => setStep(1)}>
            Редактировать
          </p>
        </div>
      )}

      <div className="ConfirmField">
        <div>
          <p className="ConfirmField-Title"> Ваш рост и вес</p>
          {`${data.height}см, ${data.weight}кг`}
        </div>
        <p className="ConfirmField-Edit" onClick={(): void => setStep(1)}>
          Редактировать
        </p>
      </div>
      {fields.map((el, index) => {
        return (
          <div className="ConfirmField" key={el}>
            <div>
              <p className="ConfirmField-Title"> {questions[el].text}</p>
              <div className="body">
                {findAnswer(questions[el], data[el]).map((answr) => {
                  return <p key={questions[el].questionPositionId}>{answr}</p>;
                })}
              </div>
            </div>
            <p className="ConfirmField-Edit" onClick={(): void => setStep(index)}>
              Редактировать
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default CheckoutConfirm;
