import { Form, Input } from 'antd';
import React, { useEffect, useRef } from 'react';
import MaskedInput from 'antd-mask-input';
import { Rule } from 'antd/lib/form';
// import Calendar from '../Calendar';
import './index.css';

interface FormInputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  type?: 'number' | 'email';
  value: string;
  numberValue?: number;
  mask?: string;
  formatCharacters?: object;
  rules?: Rule[];
  validateTrigger?: string;
  name?: string;
  min?: string | number;
  max?: string | number;
  allowClear?: boolean;
  disabled?: boolean;
  autofocus?: boolean;
  textarea?: boolean;
  inputMode?: InputMode;
  autoComplete?: string;
  normalize?: (value: string) => string;
  onChange: (value: string) => void;
  onClick?: (value: string) => void;
}

type InputMode = 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';

const FormInput: React.FunctionComponent<FormInputProps> = (props) => {
  const {
    label = '',
    value,
    placeholder,
    className = '',
    onChange,
    mask = '',
    formatCharacters,
    rules,
    name,
    validateTrigger = 'onBlur',
    normalize,
    type = 'text',
    min,
    max,
    allowClear,
    autofocus,
    textarea,
    inputMode,
    disabled = false,
    autoComplete,
  } = props;

  const [showLabel, setShowLabel] = React.useState(false);
  const [showPlaceholder, setShowPlaceholder] = React.useState(true);

  React.useEffect(() => {
    setShowLabel(value !== '');
    setShowPlaceholder(value === '');
  }, [value]);

  const handleBlur = (): void => {
    if (value !== '') {
      setShowLabel(true);
      setShowPlaceholder(false);
    } else {
      setShowLabel(false);
      setShowPlaceholder(true);
    }
  };
  const handleFocus = (): void => {
    setShowPlaceholder(false);
    setShowLabel(true);
  };

  const maskedInputRef = useRef<MaskedInput>(null);

  useEffect(() => {
    if (maskedInputRef.current) {
      maskedInputRef.current.state.input.onpaste = (event: ClipboardEvent) => {
        const value = event.clipboardData?.getData('text');
        console.log({event, value});
        if (value) {
          onChange(value);
        }
      };
    }
  });

  const styles = { '--image-clear': `url(/images/clear-input.svg)` } as React.CSSProperties;

  return (
    <div className="FormInput-Container" style={styles}>
      {showLabel && <label className="FormInput-Label">{placeholder}</label>}
      <Form.Item
        labelCol={{ flex: '1' }}
        labelAlign="left"
        label={label ? label : null}
        className={`FormInput ${className} `}
        rules={rules}
        name={name}
        normalize={normalize}
        validateTrigger={validateTrigger}
        initialValue={value}
      >
        {mask ? (
          <MaskedInput
            ref={maskedInputRef}
            className={`FormInput-Input ${showLabel ? 'FormInput-Input-Filled' : ''} `}
            placeholder={showPlaceholder ? placeholder : ''}
            value={typeof value === 'string' ? value : ''}
            mask={mask}
            formatCharacters={formatCharacters}
            onChange={(e): void => onChange(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholderChar=" "
            inputMode={inputMode}
            disabled={disabled}
            autoComplete={autoComplete}
            autoFocus={window.innerWidth > 560 ? autofocus : false}
          />
        ) : textarea ? (
          <Input.TextArea
            className={`FormInput-Input FormInput-Input--TextArea ${showLabel ? 'FormInput-Input-Filled' : ''} `}
            placeholder={showPlaceholder ? placeholder : ''}
            value={typeof value === 'string' ? value : ''}
            allowClear={allowClear}
            onChange={(e): void => onChange(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoFocus={window.innerWidth > 560 ? autofocus : false}
            autoCapitalize="sentences"
            rows={4}
            disabled={disabled}
            autoComplete={autoComplete}
          />
        ) : (
          <Input
            type={type}
            disabled={disabled}
            className={`FormInput-Input ${showLabel ? 'FormInput-Input-Filled' : ''} `}
            placeholder={showPlaceholder ? placeholder : ''}
            value={typeof value === 'string' ? value : ''}
            min={min ? min : undefined}
            max={max ? max : undefined}
            allowClear={allowClear}
            onChange={(e): void => onChange(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoFocus={window.innerWidth > 560 ? autofocus : false}
            inputMode={inputMode}
            autoComplete={autoComplete}
          />
        )}
      </Form.Item>
    </div>
  );
};

export default FormInput;
