import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../Button';
import Checkbox from '../Checkbox';
import { formatPrice } from '../../utils/price';
import { ordersStore, payStore, userStore } from '../../store';
import './index.css';
import { OrderStatus } from '../../types/enums/orders';

interface CheckoutButtonsProps {
  currentSection: CheckoutSection;
  isDisabled: boolean;
  isMobile: boolean;
  step: number;
  handleNextClick: (e: React.SyntheticEvent) => void;
  handlePrevClick: () => void;
  handlePayClick: () => void;
}

const CheckoutButtons: React.FC<CheckoutButtonsProps> = observer((props) => {
  const { currentSection, step, isDisabled, handleNextClick, handlePayClick, handlePrevClick } = props;
  const { order } = ordersStore;
  const { allowUsePersonalData } = userStore;

  const isButtonsHidden = currentSection.name === 'result' && order !== null && order.status !== OrderStatus.ReadyForDelivery;
  if (isButtonsHidden) {
    return null;
  }

  const isBackButtonVisible = (step > 0 && currentSection.name === 'health') || currentSection.name === 'auth';

  if (currentSection.name === 'pay' && payStore.currentOption === null) {
    return <></>;
  }

  const renderCheckoutButtons = () => {
    if (currentSection.name === 'pay') {
      if (payStore.currentOption !== null) {
        return (<div className="Checkout-Pay">
          <Button form disabled={isDisabled} onClick={handlePayClick}>
            Оплатить заказ <span className="Checkout-Price">{formatPrice(payStore.currentOption.price)}</span>
          </Button>
        </div>);
      }
      return;
    }
    if (currentSection.name === 'health' && step === 9) {
      return (
        <Button form disabled={isDisabled} onClick={handleNextClick}>
          Подтвердить
        </Button>
      );
    }
    if (currentSection.name === 'result' && order !== null && order.status === OrderStatus.ReadyForDelivery) {
      return (
        <Button form disabled={isDisabled} onClick={handleNextClick}>
          Подтвердить
        </Button>
      );
    }
    return (
      <Button form disabled={isDisabled} onClick={handleNextClick}>
        Продолжить
      </Button>
    );
  };

  const handleCheckboxClick = (value: boolean) => {
    userStore.setAllowUsePersonalData(value);
  }

  return (
    <div className="Checkout-Buttons">
      {currentSection.name === 'personal' &&

        <Checkbox checked={allowUsePersonalData} onClick={handleCheckboxClick} label={(
          <p>Я выражаю согласие с условиями <a href="https://assets.website-files.com/60fe0162ef262b855a8e0d9e/60fe0162ef262b03418e0fb2_TermsConditions%20-%202021_April%20-%20RU.pdf" rel="noreferrer" target="_blank">пользовательного соглашения</a> и даю согласие на <a href="https://bioniq.com/ru/privacy" rel="noreferrer" target="_blank">обработку персональных данных</a></p>
        )} />
      }
      <div className="Checkout-ButtonsWrapper">
        {renderCheckoutButtons()}
        {isBackButtonVisible && (
          <Button back form={false} onClick={handlePrevClick}>
            Назад
          </Button>
        )}
      </div>
    </div>
  );
});

export default CheckoutButtons;
