import { Gender, humanDateToISO, questions } from ".";

const weightOptions = [
  {
    condition: (val: number) => val < 50,
    answerPositionId: '5f77076d-0cce-46b3-9d61-8d7c9cfbe8ca',
  },
  {
    condition: (val: number) => val >= 50 && val < 70,
    answerPositionId: 'd4efb233-1bc4-447d-974e-a74b46e0fa82',
  },
  {
    condition: (val: number) => val >= 70 && val < 90,
    answerPositionId: 'e2de5daa-e993-430b-977e-9b82ccb43c63',
  },
  {
    condition: (val: number) => val >= 90,
    answerPositionId: 'cac1bfb3-9c23-4992-9c9a-a4287851d82d',
  },
];

const heightOptions = [
  {
    condition: (val: number) => val < 150,
    answerPositionId: '5e90a0b7-1e97-4ea2-bb38-4d0a2d1e8a66',
  },
  {
    condition: (val: number) => val >= 150 && val < 170,
    answerPositionId: '986ca978-611d-4252-98d5-73c72f81f28b',
  },
  {
    condition: (val: number) => val >= 170,
    answerPositionId: 'd9e71a6d-deec-4728-99ff-fc88917f3a66',
  },
];

const bmiOptions = [
  {
    condition: (val: number) => val < 18.5,
    answerPositionId: '90a9a377-039c-44ac-ac52-59b388239494',
  },
  {
    condition: (val: number) => val >= 18.5 && val < 25,
    answerPositionId: 'ab2da7fa-3ddd-424b-95cb-4341721e0c46',
  },
  {
    condition: (val: number) => val >= 25 && val < 30,
    answerPositionId: '8d0b560b-1b05-4a2e-b4c9-2dfb8e29d0c1',
  },
  {
    condition: (val: number) => val >= 30 && val < 35,
    answerPositionId: 'b4d6ca51-8759-4f43-97d9-81b463a45336',
  },
  {
    condition: (val: number) => val >= 35 && val < 40,
    answerPositionId: '7659478e-cdf9-4412-b98d-f9b930edc2d0',
  },
  {
    condition: (val: number) => val >= 40,
    answerPositionId: '43b4675e-b33a-4a5a-b304-f5209d6018c7',
  },
]

export const prepareData = (healthForm: HealthForm): AddHealthFormRequest => {
  const user = {
    genderId: healthForm.gender,
    birthDate: humanDateToISO(healthForm.birthDate as string),
  };

  const answers: Answer[] = [];
  let height: number = 0;
  let weight: number = 0;
  Object.entries(questions).forEach(([questionName, questionId])  => {
    const answerValue = (healthForm as any)[questionName];
    questionId = questionId === questions.goals ? questions.recs : questionId;

    if (!answerValue) {
      return;
    }

    if (questionId === questions.vits || questionId === questions.vitsQuantity) {
      return;
    }

    if (
      questionId === questions.pregnancy &&
      healthForm.gender === Gender.MALE
    ) {
      return;
    }

    if (questionId === questions.weight) {
      // вес
      weight = parseInt(answerValue);
      const answer = weightOptions.find((opt) => opt.condition(weight));
      if (answer) {
        answers.push({
          questionPositionId: questionId,
          answerPositionId: answer.answerPositionId,
        });
      } else {
        console.warn("Not found weight option", {
          answerValue,
          healthForm,
        });
      }
      return;
    }

    if (questionId === questions.height) {
      // рост
      height = parseInt(answerValue);
      const answer = heightOptions.find((opt) => opt.condition(height));
      if (answer) {
        answers.push({
          questionPositionId: questionId,
          answerPositionId: answer.answerPositionId,
        });
      } else {
        console.warn("Not found height option", {
          answerValue,
          healthForm,
        });
      }
      return;
    }

    if (Array.isArray(answerValue)) {
      answerValue.forEach((val) => {
        if (val === "nothing") {
          return;
        }
        answers.push({ questionPositionId: questionId, answerPositionId: val });
      });
    } else {
      answers.push({
        questionPositionId: questionId.replace(/_/g, ''),
        answerPositionId: answerValue.replace(/_/g, ''),
      });
    }
  });

  if (weight && height) {
    // bmi
    const bmi = Math.round(weight / (height * 0.01) ** 2)
    const answer = bmiOptions.find((opt) => opt.condition(bmi));
    if (answer) {
      answers.push({
        questionPositionId: 'd6e27dbb-18a4-466d-955c-4dc30641af24',
        answerPositionId: answer.answerPositionId,
      });
    } else {
      console.warn("Not found weight option", {
        weight,
        height,
        bmi,
        healthForm,
      });
    }
  } else {
    console.warn('not found height or weight', { healthForm });
  }

  answers.push({
    questionPositionId: 'e7aeb839-0dc7-4d85-aac5-7613c7a3766c', // тип формулы
    answerPositionId: '291cf008-11d2-471f-8c4e-c799b1b58ac9', // гранулы
  });

  console.log('Prepare data', {healthForm,answers});

  return {
    user,
    answers,
  };
};
