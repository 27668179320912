import React, { useEffect, useState } from 'react';
import FormInput from '../FormInput';
import { Form } from 'antd';
import { dateRegExp, zipRegExp, rusEngRegExp, rusIncludeDigitsRegExp, rusRegExp } from '../../utils';
import Button from '../Button';
import Modal from '../Modal';
import { deliveryStore } from '../../store';
import './index.css';

const Delivery: React.FC = () => {
  const { addressInfo, passportData } = deliveryStore;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(addressInfo);
    form.setFieldsValue(passportData);
  }, [form, addressInfo, passportData]);

  return (
    <div className="Delivery">
      <Form form={form} validateTrigger={['onBlur', 'onMouseOut']}>
        <p className="Delivery-Title">Укажите адрес, где вам будет удобно принять доставку</p>

        <FormInput
          placeholder="Страна"
          name="country"
          value={addressInfo.country}
          normalize={(value: string): string => {
            return rusEngRegExp.test(value) ? value : value.substr(0, value.length - 1);
          }}
          onChange={(e: string): void => {
            deliveryStore.setAddressInfo(e, 'country');
          }}
          rules={[
            {
              required: true,
              min: 2,
              message: 'Введите  страну',
            },
          ]}
        />
        <FormInput
          placeholder="Город"
          name="city"
          value={addressInfo.city}
          normalize={(value: string): string => {
            return rusEngRegExp.test(value) ? value : value.substr(0, value.length - 1);
          }}
          onChange={(e: string): void => {
            deliveryStore.setAddressInfo(e, 'city');
          }}
          rules={[
            {
              required: true,
              min: 2,
              message: 'Введите город',
            },
          ]}
        />
        <FormInput
          placeholder="Адрес"
          name="addressLine"
          value={addressInfo.addressLine}
          onChange={(e: string): void => {
            deliveryStore.setAddressInfo(e, 'addressLine');
          }}
          rules={[
            {
              required: true,
              min: 2,
              message: 'Введите корректный адрес',
            },
          ]}
        />
        <FormInput
          placeholder="Почтовый индекс"
          name="zip"
          value={addressInfo.zip}
          normalize={(value: string): string => {
            return zipRegExp.test(value) && value.length <= 8 ? value : value.substr(0, value.length - 1);
          }}
          onChange={(e: string): void => {
            deliveryStore.setAddressInfo(e, 'zip');
          }}
          rules={[
            {
              required: true,
              min: 5,
              message: 'Введите корректный почтовый индекс',
            },
          ]}
        />
        <div className="Delivery-PassportTitle">
          <p className="Delivery-Title">Укажите ваши паспортные данные </p>{' '}
          <Button info onClick={(e): void => {
            e.preventDefault();
            setIsModalVisible(true);
          }} />
        </div>
        <FormInput
          placeholder="Серия и номер паспорта"
          name="number"
          inputMode="numeric"
          mask="1111 111111"
          value={passportData.number}
          onChange={(e: string): void => {
            deliveryStore.setPassportData(e, 'number');
          }}
          rules={[
            {
              message: 'Введите корректный номер паспорта',
              validator: (_, value: string): Promise<void> => {
                if (value.trim().length === 11) {
                  return Promise.resolve();
                } else {
                  return rusRegExp.test(value) ? Promise.resolve() : Promise.reject();
                }
              },
            },
          ]}
        />
        <FormInput
          placeholder="Дата выдачи"
          mask="11.11.1111"
          inputMode="numeric"
          name="issueDate"
          value={passportData.issueDate}
          onChange={(e: string): void => {
            deliveryStore.setPassportData(e, 'issueDate');
          }}
          rules={[
            {
              message: 'Введите корректную дату',
              validator: (_, value: string): Promise<void> => {
                return dateRegExp.test(value) ? Promise.resolve() : Promise.reject();
              },
            },
          ]}
        />
        <FormInput
          placeholder="Кем выдан"
          name="issuedBy"
          value={passportData.issuedBy}
          onChange={(e: string): void => {
            deliveryStore.setPassportData(e, 'issuedBy');
          }}
          rules={[
            {
              required: true,
              min: 2,
              message: 'Введите корректные данные',
            },
            {
              message: 'Введите данные на русском языке',
              validator: (_, value: string): Promise<void> => {
                if (value.length < 2) {
                  return Promise.resolve();
                } else {
                  return rusIncludeDigitsRegExp.test(value) ? Promise.resolve() : Promise.reject();
                }
              },
            },
          ]}
        />
        {/* <FormInput
          placeholder="ИНН (12 цифр)"
          name="INN"
          inputMode="numeric"
          value={passportData.INN}
          normalize={(value: string): string => {
            return /^\d+$/.test(value) && value.length <= 12 ? value : value.substr(0, value.length - 1);
          }}
          onChange={(e: string): void => {
            deliveryStore.setPassportData(e, 'INN');
          }}
          rules={[
            {
              required: true,
              min: 12,
              message: 'Введите корректный ИНН',
            },
          ]}
        />
        <p className="body Delivery-Hint">
          ИНН можно узнать на{' '}
          <a href="https://service.nalog.ru/inn.do" target="_blank" rel="noreferrer">
            сайте ФНС
          </a>{' '}
          всего за 1 минуту.
        </p> */}
      </Form>
      <Modal visible={isModalVisible} onClose={(): void => setIsModalVisible(false)}>
        <div className="DeliveryModal-Content">
          <img src="/images/passport.png" alt="passport" />
          <p className="DeliveryModal-Title">Зачем вносить паспортные данные?</p>
          <p className="body">
          Паспортные данные необходимы для таможни, так как доставка осуществляется из Великобритании. <br /><br />
          Это требования российского законодательства.
          </p>
          <Button form onClick={(): void => setIsModalVisible(false)}>
            Продолжить
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Delivery;
