export class Storage {
  static read(key: string): any {
    if (typeof window === "undefined") {
      return;
    }
    const item = localStorage.getItem(key);
    try {
      return item ? JSON.parse(item) : null;
    } catch (err) {
      return null;
    }
  }

  static write(key: string, value: any): any {
    if (typeof window === "undefined") {
      return;
    }
    localStorage.setItem(key, JSON.stringify(value));
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }

  static clear(): any {
    localStorage.clear();
  }
}
