import { makeAutoObservable } from 'mobx';
import { productStore } from '.';
import { applyCoupon } from '../api';
import { Product } from '../utils';
import config from '../utils/config';

export class PriceStore {
  price: MappedPrice | null = null;
  coupon: MappedExtendedPrice | null = null;
  couponCode: string = '';
  isCouponError: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getPrices(product: Product): Promise<void> {
    // const price = await obtainPrice(product);
    // this.price = price;
  }

  get amount(): number {
    // if (this.price !== null) {
    //   return this.price.amount;
    // }
    return config.GO_PRICE;
  }

  setCouponCode(code: string): void {
    this.couponCode = code;
  }
  setCoupon(coupon: MappedExtendedPrice | null): void {
    this.coupon = coupon;
  }
  setIsCouponError(value: boolean): void {
    this.isCouponError = value;
  }

  async applyCoupon(code: string): Promise<void> {
    try {
      const res = await applyCoupon(code, productStore.product);
      // this.setPrice(res);
      this.setCoupon(res);
      this.setCouponCode(code);
      this.setIsCouponError(false);
    } catch (err) {
      console.log(err);
      this.setIsCouponError(true);
    }
  }
  discardCoupon = (): void => {
    this.setCoupon(null);
    this.setCouponCode('');
    if (this.coupon !== null) {
      // this.setPrice(mappedExtendedPriceToMappedPrice(this.coupon));
    }
  };

  get benefitAmount(): number {
    if (this.coupon === null) {
      return 0;
    }
    let price: { amount: number; fullAmount: number } = this.coupon;
    return Math.round(price.fullAmount - price.amount);
  }
}
