import { loadStripe, Stripe } from "@stripe/stripe-js";
import config from "./config";

export const loadPaymentSystem = async (): Promise<Stripe | null> => {
  return loadStripe(config.STRIPE_PUBLISHABLE_KEY);
};

// export const checkApplePay = async (): Promise<boolean> => {
//   if (typeof ApplePaySession === 'undefined') {
//     return false;
//   }
//   const merchantIdentifier = config.APPLE_PAY_MERCHANT_ID;
//   try {
//     const canMakePayments = await ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
//     if (canMakePayments) {
//       return true;
//     }
//   } catch (err) {
//     console.error(err);
//   }
//   return false;
// };

// export async function isReadyToGooglePay(): Promise<{ paymentsClient: any; result: boolean }> {
//   const paymentsClient = new google.payments.api.PaymentsClient({ environment: config.GOOGLE_PAY_ENV });
//   const allowedPaymentMethods = getAllowedPaymentMethods();
//   const { result } = await paymentsClient.isReadyToPay({
//     apiVersion: 2,
//     apiVersionMinor: 0,
//     allowedPaymentMethods,
//   });
//   return { paymentsClient, result };
// }

// export const getAllowedPaymentMethods = (): google.payments.api.PaymentMethodSpecification[] => {
//   return [
//     {
//       type: 'CARD',
//       parameters: {
//         allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
//         allowedCardNetworks: ['MASTERCARD', 'VISA'],
//       },
//       tokenizationSpecification: {
//         type: 'PAYMENT_GATEWAY',
//         parameters: {
//           gateway: 'cloudpayments',
//           gatewayMerchantId: config.CLOUDPAYMENTS_PUBLIC_ID,
//         },
//       },
//     },
//   ];
// };

// let checkGooglePayAttemps = 0;
// export const checkGooglePay = async (): Promise<void> => {
//   if (typeof google === 'undefined') {
//     if (checkGooglePayAttemps > 10) {
//       return;
//     }
//     return new Promise(res => setTimeout(async () => {
//       checkGooglePayAttemps++;
//       await checkGooglePay();
//       res();
//     }, 500));

//   }

//   const { paymentsClient, result } = await isReadyToGooglePay();

//   if (result) {
//     const googlePayElement = document.querySelector('#google-pay');
//     if (googlePayElement === null) {
//       return;
//     }
//     googlePayElement.innerHTML = '';
//     googlePayElement.appendChild(
//       paymentsClient.createButton({
//         onClick: () => null,
//         buttonColor: 'black',
//         buttonType: 'plain',
//         buttonSizeMode: 'fill',
//       })
//     );
//   }
// };
