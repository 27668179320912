import { formatJSON } from "../utils";
// import { request } from "./common";
import data from './health.json';

// export const getHealthQuestions = async (): Promise<Questionaire> => {
//   const questions = await request<any>('Questionaries/ObtainBalanceTestQuestionaryBlank');
//   return questions;
// };

export const getHealthQuestions = async (): Promise<any> => {
  return formatJSON(data);
}
