import { makeAutoObservable } from "mobx";
import { PayOption } from "../types/enums/options";
import { Storage } from "../utils/storage";

export const options: PayOptionItem[] = [
  {
    title: '1 месяц',
    value: PayOption.ONE,
    price: 3900,
    description: 'В декабре доставка бесплатно',
    freeDelivery: false,
  },
  {
    title: '2 месяца',
    value: PayOption.TWO,
    price: 7800,
    freeDelivery: true,
  },
  {
    title: '3 месяца',
    value: PayOption.THREE,
    price: 9900,
    description: 'Экономия 1800 ₽',
    freeDelivery: true,
  },
];

export class PayStore {
  _option: PayOption | null = null;
  private storageOptionKey = 'payOption';

  constructor() {
    makeAutoObservable(this);
    this.initPayOption();
  }

  get option() {
    return this._option;
  }

  set option(value) {
    this._option = value;
    Storage.write(this.storageOptionKey, value);
  }

  get currentOption() {
    if (this.option === null) {
      return null;
    }
    const opt = options.find(o => o.value === this.option);
    return opt ? opt : null;
  }

  private initPayOption() {
    const option = this.getPayOptionFromUrl() || this.getPayOptionFromStorage();
    if (option in PayOption) {
      this.option = option;
    }
  }

  private getPayOptionFromStorage() {
    return Storage.read(this.storageOptionKey);
  }

  private getPayOptionFromUrl() {
    const key = 'quantity';
    const params = new URLSearchParams(window.location.search);
    const option = params.get(key);
    if (option !== null) {
      params.delete(key);
      window.location.search = params.toString();
      return parseInt(option, 10);
    }
    return null;
  }
}
