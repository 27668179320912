import { makeAutoObservable } from 'mobx';
import { Storage } from '../utils/storage';

export class DeliveryStore {
  addressInfo: Address = { country: '', city: '', addressLine: '', zip: '' };
  passportData: Passport = { number: '', issueDate: '', issuedBy: '', INN: '' };

  readonly storageKey = 'deliveryInfo';

  constructor() {
    makeAutoObservable(this);
    const delivery = Storage.read(this.storageKey);
    if (delivery) {
      this.addressInfo = delivery.address;
      this.passportData = delivery.passport;
    }
  }

  setAddressInfo(value: string, name: string): void {
    this.addressInfo = { ...this.addressInfo, [name]: value };
  }

  setPassportData(value: string, name: string): void {
    this.passportData = { ...this.passportData, [name]: value };
  }

  save(): void {
    Storage.write(this.storageKey, { address: this.addressInfo, passport: this.passportData });
  }
}
