import React, { useState } from 'react';
import FormInput from '../FormInput';
import './index.css';
import Radio from '../Radio';
import MultipleSelect from '../MultipleSelect';
import CheckoutConfirm from '../CheckoutConfirm';
import { Form } from 'antd';
import { getHealthQuestions } from '../../api/health';
import Loader from '../Loader';
import { dateRegExp, Gender } from '../../utils';
interface HealthFormProps {
  data: HealthForm;
  step: number;
  handleChange: (value: string | string[], name: string) => void;
  setStep: (num: number) => void;
  isLoading: boolean;
}

const nothingOption = {
  answerPositionId: 'nothing',
  text: 'Ничего из вышеперечисленного',
};

export const vitsOptions = [
  {
    answerPositionId: "b46ba8e8-b1d9-424e-94bb-448395a48a44",
    text: "Да, пью сейчас"
  },
  {
    answerPositionId: "d772a630-e923-490b-a531-af6b550c568f",
    text: "Да, пил(а)"
  },
  {
    answerPositionId: "5f07173c-c2e9-4cc9-9653-f952896af545",
    text: "Нет, не пил(а)"
  }
];

export const genderOptions = [
  { answerPositionId: Gender.MALE, text: 'Мужчина' },
  { answerPositionId: Gender.FEMALE, text: 'Женщина' },
];

const HealthForm: React.FC<HealthFormProps> = (props) => {
  const [questionnaire, setQuestionnaire] = useState<Questionaire>();
  const [isLoading, setIsLoading] = useState(true);
  const { step, handleChange, isLoading: load } = props;
  const {
    // fatherName,
    birthDate,
    gender,

    vits,
    vitsQuantity,
    height,
    weight,
    lifestyle,
    allergy,
    sickness,
    recs,
    meds,
    goals,
    diet,
    pregnancy,
  } = props.data;

  React.useEffect(() => {
    async function loadQuestions(): Promise<void> {
      setIsLoading(true);
      setQuestionnaire(await getHealthQuestions());
      setIsLoading(false);
    }
    if (questionnaire == null) {
      loadQuestions();
    }
  }, [questionnaire]);

  const handleMultipleSelectClick = (val: string, field: 'meds' | 'recs' | 'allergy' | 'sickness' | 'goals'): void => {
    const newValues = [...props.data[field]];
    if (newValues.includes(val)) {
      newValues.splice(newValues.indexOf(val), 1);
    } else {
      newValues.push(val);
    }
    if (newValues.includes('nothing')) {
      newValues.splice(newValues.indexOf('nothing'), 1);
    }
    handleChange(val === 'nothing' ? ['nothing'] : newValues, field);
  };

  const [form] = Form.useForm();

  return (
    <div className="HealthForm">
      {!questionnaire || isLoading || load ? (
        <Loader />
      ) : (
        <>
          <Form form={form} validateTrigger={['onBlur', 'onMouseOut']}>

            {step === 0 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.vits.text}</p>
                <Radio
                  vertical
                  answers={questionnaire.vits.answers}
                  value={vits}
                  onChange={(val): void => {
                    handleChange(val, 'vits');
                  }}
                />

                {(vits === vitsOptions[0].answerPositionId || vits === vitsOptions[1].answerPositionId) && (
                  <>
                    <p className="body-secondary HealthForm-Title">{questionnaire.vitsQuantity.text}</p>
                    <Radio
                      vertical
                      answers={questionnaire.vitsQuantity.answers}
                      value={vitsQuantity}
                      onChange={(val): void => {
                        handleChange(val, 'vitsQuantity');
                      }}
                    />
                  </>
                )}
              </>
            )}
            {step === 1 && (
              <>
                <p className="body-secondary HealthForm-Title">Персональные данные</p>
                <FormInput
                  placeholder="Дата рождения"
                  allowClear
                  value={birthDate}
                  mask="11.11.1111"
                  inputMode="numeric"
                  name="birthDate"
                  onChange={(e: string): void => {
                    handleChange(e.trim(), 'birthDate');
                  }}
                  rules={[
                    {
                      message: 'Введите корректную дату рождения',
                      validator: (_, value: string): Promise<void> => {
                        return dateRegExp.test(value) ? Promise.resolve() : Promise.reject();
                      },
                    },
                  ]}
                />
                <p className="body-secondary HealthForm-Title">Ваш пол</p>

                <Radio
                  className="Row"
                  answers={genderOptions}
                  value={gender}
                  onChange={(val): void => {
                    handleChange(val, 'gender');
                  }}
                />
                {gender === genderOptions[1].answerPositionId && (
                  <>
                    <p className="body-secondary HealthForm-Title">{questionnaire.pregnancy.text}</p>
                    <Radio
                      className="Row"
                      answers={questionnaire.pregnancy.answers}
                      value={pregnancy}
                      onChange={(val): void => {
                        handleChange(val, 'pregnancy');
                      }}
                    />
                  </>
                )}
                <div className="HealthForm-Container">
                  <div>
                    <p className="body-secondary HealthForm-Title">Ваш рост</p>
                    <FormInput
                      className="HealthForm-Height"
                      placeholder="В см"
                      inputMode="numeric"
                      value={height}
                      name="height"
                      mask="111"
                      onChange={(e: string): void => {
                        handleChange(e, 'height');
                      }}
                      rules={[
                        {
                          message: 'Введите корректный рост',
                          validator: (_, value: string): Promise<void> => {
                            const h = parseInt(value);
                            return h >= 50 && h <= 210 ? Promise.resolve() : Promise.reject();
                          },
                        },
                      ]}
                    />
                  </div>
                  <div>
                    <p className="body-secondary HealthForm-Title">Ваш вес</p>
                    <FormInput
                      placeholder="В кг"
                      inputMode="numeric"
                      value={weight}
                      name="weight"
                      mask="111"
                      rules={[
                        {
                          message: 'Введите корректный вес',
                          validator: (_, value: string): Promise<void> => {
                            const h = parseInt(value);
                            return h >= 10 && h <= 200 ? Promise.resolve() : Promise.reject();
                          },
                        },
                      ]}
                      onChange={(e: string): void => {
                        handleChange(e, 'weight');
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.lifestyle.text}</p>
                <Radio
                  vertical
                  answers={questionnaire.lifestyle.answers}
                  value={lifestyle}
                  onChange={(val): void => {
                    handleChange(val, 'lifestyle');
                  }}
                />
              </>
            )}
            {step === 3 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.allergy.text}</p>
                <MultipleSelect
                  value={allergy}
                  answers={[...questionnaire.allergy.answers, nothingOption]}
                  onChange={(val): void => {
                    handleMultipleSelectClick(val, 'allergy');
                  }}
                />
              </>
            )}
            {step === 4 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.sickness.text}</p>
                <p className="HealthForm-Warning">
                  Пожалуйста, отметьте данный диагноз только в том случае, если он был поставлен врачом
                </p>
                <MultipleSelect
                  value={sickness}
                  answers={[...questionnaire.sickness.answers, nothingOption]}
                  onChange={(val): void => {
                    handleMultipleSelectClick(val, 'sickness');
                  }}
                />
              </>
            )}
            {step === 5 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.recs.text}</p>
                <MultipleSelect
                  value={recs}
                  answers={[...questionnaire.recs.answers, nothingOption]}
                  onChange={(val): void => {
                    handleMultipleSelectClick(val, 'recs');
                  }}
                />
              </>
            )}
            {step === 6 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.meds.text}</p>
                <MultipleSelect
                  value={meds}
                  answers={[...questionnaire.meds.answers, nothingOption]}
                  onChange={(val): void => {
                    handleMultipleSelectClick(val, 'meds');
                  }}
                />
              </>
            )}
            {step === 7 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.goals.text}</p>
                <MultipleSelect
                  value={goals}
                  answers={[...questionnaire.goals.answers, nothingOption]}
                  onChange={(val): void => {
                    handleMultipleSelectClick(val, 'goals');
                  }}
                />
              </>
            )}

            {step === 8 && (
              <>
                <p className="body-secondary HealthForm-Title">{questionnaire.diet.text}</p>
                <Radio
                  vertical
                  answers={questionnaire.diet.answers}
                  value={diet}
                  onChange={(val): void => {
                    handleChange(val, 'diet');
                  }}
                />
              </>
            )}
          </Form>
          {step === 9 && <CheckoutConfirm data={props.data} setStep={props.setStep} questions={questionnaire} />}
        </>
      )}
    </div>
  );
};

export default HealthForm;
