import React from "react";
import ReactCodeInput from "react-verification-code-input";
import './index.css';

interface CodeInputProps {
  onChange?: (val: string) => void;
  onSubmit: (val: string) => void;
  loading?: boolean;
}

const CodeInput = React.forwardRef<ReactCodeInput, CodeInputProps>(({ onChange = () => null, onSubmit, loading = false }, ref) => {
  return (
    <div className="CodeInput">
      <ReactCodeInput
        ref={ref}
        onChange={onChange}
        onComplete={onSubmit}
        fields={4}
        fieldWidth={100}
        fieldHeight={72}
        loading={loading}
        autoFocus={true}
      />
    </div>
    );
});

export default CodeInput;
