import React from 'react';
import { Form } from 'antd';
import './index.css';
import CodeInput from '../CodeInput';

interface CheckoutAuthProps {
  isError: boolean;
  handleChange: (e: string) => void;
  handleSubmit: (value: string) => void;
}

const CheckoutAuth: React.FC<CheckoutAuthProps> = ({ isError, handleSubmit, handleChange }) => {
  const [form] = Form.useForm();

  return (
    <div className="CheckoutAuth">
      <Form form={form} validateTrigger={['onBlur', 'onMouseOut']}>
        <CodeInput
          onChange={handleChange}
          onSubmit={(value) => handleSubmit(value)}
        />
        {isError && <div className="CheckoutAuth-Error">Неверный код</div>}
      </Form >
    </div >
  );
};

export default CheckoutAuth;
