import config from "../utils/config";
import { postRequest } from "./common";

export class PaymentApi {
  static baseUrl = config.PRODUCTS_API;

  static async createIntent(): Promise<any> {
    // return postRequest("/shop/create-intent", {  });
  }

  static async purchaseGo(data: PurchaseGoRequest): Promise<PurchaseGoResponse> {
    return postRequest('/shop/go', data);
  }

  static async verifyGoPurchase(data: PurchaseGoVerifyRequest): Promise<any> {
    return postRequest('/shop/go/verify', data);
  }
}
