import React, { useEffect } from 'react';
import Loader from '../Loader';
import './index.css';
import { parseISO, addMonths } from 'date-fns';
import format from '../../utils/date';
import Header from '../Header';
import { useHistory } from 'react-router-dom';
import { ordersStore, userStore } from '../../store';
import { observer } from 'mobx-react';
import ResultTable from '../ResultTable';
import { OrderStatus as Status } from '../../types/enums/orders';
// import { Storage } from '../../utils/storage';
// import { gtmPushSuccessPay, snowplowAddItem, snowplowAddTransaction, sendFormSuccessPay } from '../../utils/analytics';

const OrderStatus: React.FunctionComponent = observer(() => {
  const { isLoading, order, recipe } = ordersStore;
  const { user } = userStore;
  const history = useHistory();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (user === null || order === null || ![Status.Delivery, Status.Complete].includes(order.status)) {
      history.replace('/');
    }
  }, [isLoading, order, history, user]);

  // const sendAnalytics = (orderId: string): void => {
  //   const purchaseInfo = Storage.read('purchaseInfo');
  //   if (purchaseInfo) {
  //     const product = `bioniq ${purchaseInfo.product?.replace(/-/g, ' ').toUpperCase()}`;

  //     snowplowAddItem({ orderId, price: purchaseInfo.price, product });
  //     snowplowAddTransaction(orderId, purchaseInfo.price);
  //     gtmPushSuccessPay({ orderId, price: purchaseInfo.price, product });
  //     sendFormSuccessPay();

  //     localStorage.removeItem('purchaseInfo');
  //   }
  // };

  useEffect(() => {
    const getOverview = async (): Promise<void> => {
      // setIsLoading(true);

      // const parameters = new URLSearchParams(window.location.search);
      // const orderNumber = parameters.get('orderNumber');
      // const token = parameters.get('token');
      // if (orderNumber === null || token === null) {
      //   setIsLoadingError(true);
      //   return;
      // }

      // try {
      //   const overview = await getOrderState(orderNumber, token);
      //   setOrder(overview);
      //   sendAnalytics(orderNumber);
      //   if (overview.state !== 'InProgress' && overview.state !== 'Complete') {
      //     setIsLoadingError(true);
      //   }
      // } catch (err) {
      //   console.error(err);
      //   setIsLoadingError(true);
      // } finally {
      //   setIsLoading(false);
      // }
    };
    getOverview();
  }, []);

  const getFormattedDate = (rawDate: string): string => {
    let date = parseISO(rawDate);
    const day = date.getDate();
    if (day > 15) {
      date = addMonths(date, 1);
    }
    return format(date, 'LLLL yyyy');
  };

  const isError = order === null;

  const styles = {
    '--image-arrow-left': `url(/images/left.svg})`,
  } as React.CSSProperties;

  return (
    <div className={`OrderStatus ${isError ? 'OrderStatus--Error' : ''}`} style={styles}>
      <Header orderStatus={!isError} />
      <div className="StatusPage">
        {isLoading || order === null || recipe === null || user === null ? (
          <Loader />
        ) : (
          <>
            <div className="StatusPage-OrderInfo">
              <div className="StatusPage-Content">
                <img src={order.status === Status.Complete ? '/images/result-ready.png' : '/images/result-waiting.png'} alt="result-success" />
                <p className="StatusPage-Title">{user.givenName}</p>
                <p className="body">
                  {order.status === Status.Complete ?
                    <>
                      Ура! Ваш персонализированный комплекс доставлен. <br /> Спасибо, что заботитесь о здоровье с bioniq!
                    </> :
                    <>
                      Мы начали готовить ваш индивидуальный комплекс. <br /> Ожидайте доставку в течение 7-10 дней. С вами свяжется курьерская служба.
                    </>
                    }
                </p>
              </div>
            </div>

            <div className="StatusPage-Card">
              <div className="StatusPage-Result">
                <div className="StatusPageResultHeader">
                  <img src={'/images/result-success.png'} alt="result-success" />
                  <div className="StatusPageResultHeader__Title">Дозировка</div>
                  <div>{getFormattedDate(recipe.date)}</div> {/* если заказ создан после 15 числа, то показывать следующий месяц */ }
                </div>
                <ResultTable recipe={recipe} />
              </div>
            </div>

            <section>
              <div className="StatusPage-HowToPrepare">
                <div className="StatusPage-Content">
                  <p className="StatusPage-Subtitle">Ваша программа bioniq GO</p>
                  <p className="StatusPage-Description">
                    Рекомендации
                  </p>
                  <ul>
                    <li>Пейте как минимум 2 литра воды в день</li>
                    <li>Принимать формулу bioniq GO по 2 мерной ложки 1 раз в день во время или после еды</li>
                  </ul>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
});

export default OrderStatus;
