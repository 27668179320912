import * as React from 'react';
import Signin from './Signin';
import './index.css';
import Verify from './Verify';
import { Storage } from '../../utils/storage';
import { observer } from 'mobx-react';
import { ordersStore, userStore } from '../../store';
import useInit from '../Checkout/useInit';

const OTP_LOGIN_KEY = 'OTPLogin';
const OTP_SENT_AT_KEY = 'OTPSentAt';

const Login: React.FunctionComponent = observer(() => {
  const [OTPLogin, setOTPLogin] = React.useState<string | null>(null);
  const [OTPSentAt, setOTPSentAt] = React.useState<Date | null>(null);
  const [isLoggedInByEmail, setIsLoggedInByEmail] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState(false);
  useInit({
    isAuthorized: userStore.isAuthorized,
    isLoading: userStore.isLoading || ordersStore.isLoading,
    order: ordersStore.order,
    recipe: ordersStore.recipe,
  });

  const handleSendOTP = async (login: string, isEmail: boolean = true): Promise<void> => {
    setIsLoading(true);
    try {
      await userStore.auth(login);
      Storage.write(OTP_LOGIN_KEY, login);
      setOTPLogin(login);
      const date = new Date();
      Storage.write(OTP_SENT_AT_KEY, date.toISOString());
      setOTPSentAt(date);
  
      setIsLoggedInByEmail(isEmail);
    } catch (err) {

    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBackToLogin = (): void => {
    Storage.remove(OTP_LOGIN_KEY);
    setOTPLogin(null);
    Storage.remove(OTP_SENT_AT_KEY);
    setOTPSentAt(null);
  };

  const handleResendOTP = (): void => {
    if (OTPLogin) {
      handleSendOTP(OTPLogin, isLoggedInByEmail);
    } else {
      handleGoBackToLogin();
    }
  };
  const handleSetIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };
  React.useEffect(() => {
    setOTPLogin(Storage.read(OTP_LOGIN_KEY));
    const sentAt = Storage.read(OTP_SENT_AT_KEY);
    if (sentAt !== null) {
      setOTPSentAt(new Date(sentAt));
    }
  }, []);

  return (
    <div className="LoginLayout">
      <div className="LoginLayout-Container">
        <aside className="LoginLayout-Aside"></aside>
        <main className="LoginLayout-Body">
          <section className="Login">
            {OTPLogin && OTPSentAt ? (
              <Verify
                isLoading={isLoading}
                login={OTPLogin}
                sentAt={OTPSentAt}
                isLoggedInByEmail={isLoggedInByEmail}
                handleResend={handleResendOTP}
                setIsLoading={handleSetIsLoading}
                handleGoBack={handleGoBackToLogin}
              />
            ) : (
              <Signin isLoading={isLoading} handleSendOTP={handleSendOTP} />
            )}
          </section>
        </main>
      </div>
    </div>
  );
});

export default Login;
