import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router';
import Button from '../Button';
import FormInput from '../FormInput';
import { emailRegExp, isPhoneValid } from '../../utils';

interface SigninProps {
  isLoading: boolean;
  handleSendOTP: (login: string, type?: boolean) => void;
}

const Signin: React.FC<SigninProps> = (props) => {
  const { isLoading, handleSendOTP } = props;
  const [login, setLogin] = useState('');
  const [, setIsValid] = useState(true);
  const history = useHistory();
  const [form] = Form.useForm();

  const _login = React.useMemo(() => login.replace(/[\s()-]/g, ''), [login])

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    if (_login.length === 0) {
      return;
    }
    if (emailRegExp.test(_login)) {
      setIsValid(true);
      handleSendOTP(_login);
    } else if (isPhoneValid(_login)) {
      setIsValid(true);
      handleSendOTP(_login, false);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ phone: login });
  }, [login, form])
  
  return (
    <>
      <div className="Login-Container">
        <h4>Вход в личный кабинет</h4>
        <p className="body Login-Subtitle">Пожалуйста, введите свой номер телефона, указанный при регистрации</p>
        <Form form={form}>
          <FormInput
            autofocus
            placeholder="Номер телефона"
            name="phone"
            value={login}
            mask="+7 (111) 111-11-11"
            inputMode="numeric"
            onChange={(e: string): void => {
              setLogin(e);
            }}
            rules={[
              {
                required: true,
                len: 18,
                message: 'Введите корректный номер телефона',
                validator: (_, value: string): Promise<void> => {
                  return isPhoneValid(value) ? Promise.resolve() : Promise.reject();
                },
              },
            ]}
          />
          <p className="Login-Hint">Например, +7 (999) 888-77-66</p>
          <Button form onClick={handleSubmit} disabled={login.length === 0 || isLoading}>
            Войти
          </Button>
        </Form>
      </div>
      <a className="Login-Reg" href="#login" onClick={(e) => [e.preventDefault(), history.push('/personal')]}>Регистрация</a>
    </>
  );
};

export default Signin;
