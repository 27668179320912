import config from "../utils/config";
import { postRequest } from "./common";

export class AuthApi {
  static baseUrl = config.PRODUCTS_API;

  static async auth(phone: string): Promise<void> {
    return postRequest("/auth", { phone });
  }

  static async signup(data: any): Promise<void> {
    return postRequest("/auth/signup", data);
  }

  static async verify(phone: string, code: string): Promise<AuthVerifyResponse> {
    return postRequest("/auth/verify", { phone, code });
  }
}
