import { dehumanizePhone } from '../utils';
import config from '../utils/config';
import { collectAnalytics } from '../utils/analytics';
import { HttpError, postRequest } from './common';

export async function saveUserContacts(info: LeadInfo): Promise<void> {
  const payload = {
    ...info,
    phone: dehumanizePhone(info.phone),
    email: info.email.toLowerCase(),
    analytics: collectAnalytics(),
  };
  try {
    const res = await postRequest<LeadInfo, { result: boolean }>('/leads', payload, config.LEADS_API);
    if (!res.result) {
      throw new Error("Save User Contact request error");
    }
  } catch (err) {
    const response = await fetch(config.LEGACY_LEADS_API, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    const model = await response.json();
    if (response.ok) {
      return model;
    } else {
      throw new HttpError(response.status, model);
    }
  }
}
