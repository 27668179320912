import React from 'react';
import './index.css';

interface ResultTableProps {
  recipe: MedRecipe;
}

const ResultTable: React.FC<ResultTableProps> = ({ recipe }) => {
    return (
      <div className="Result">
        <div className="ResultTable">
          <div className="ResultTable__Head">
            <div className="ResultTable__Column">
              Элемент
            </div>
            <div className="ResultTable__Column">
              Количество
            </div>
            <div className="ResultTable__Column">
              %NRV*
            </div>
          </div>

          {recipe.micronutrients.map(nutrient => (
            <div key={nutrient.name} className="ResultTable__Row">
              <div className="ResultTable__Column">
                {nutrient.name}
              </div>
              <div className="ResultTable__Column">
                {nutrient.dailyDose.value} {nutrient.dailyDose.unit}
              </div>
              <div className="ResultTable__Column">
                {nutrient.nrvPercent ? `${nutrient.nrvPercent}%` : '**'}
              </div>
            </div>
          ))}

          <div className="ResultTable__Disclaimer">
            * — %NRV — доля от рекомендуемой суточной дозировки<br />
            ** — %NRV не определен
          </div>
        </div>
      </div>
    );
};

export default ResultTable;
