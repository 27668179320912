import config from "../utils/config";
import { request } from "./common";

export class UserApi {
  static baseUrl = config.PRODUCTS_API;

  static async getUser(): Promise<User> {
    return request<User>("/users/me");
  }
}
