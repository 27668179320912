import { formatJSON, Product } from '../utils';
import { extendedPriceToMappedExtendedPrice, priceToMappedPrice } from '../utils/price';
import { request, postRequest } from './common';

export const getHealthQuestions = async (): Promise<Questionaire> => {
  const { regularQuestions } = await request('Questionaries/ObtainBalanceTestQuestionaryBlank');
  return formatJSON(regularQuestions);
};

export const purchaseRequest = async (req: PurchaseRequest): Promise<PurchaseResponse> => {
  const url = 'Shop/PurchaseGo';
  return postRequest(url, req);
};

export const startApplePaySession = async (req: StartApplePaySessionRequest): Promise<StartApplePaySessionResponse> => {
  return postRequest('Shop/StartApplePaySession', req);
};

export const obtainOverview = async (orderNumber: string, token: string): Promise<Overview> => {
  const dict = { orderNumber, token };
  const parameters = Object.entries(dict).map(([name, value]) => ({ name, value }));
  return request('Results/ObtainOverview', parameters);
};

export const getOrderState = async (orderNumber: string, token: string): Promise<OrderWithState> => {
  const dict = { orderNumber, token };
  const parameters = Object.entries(dict).map(([name, value]) => ({ name, value }));
  return request('Orders/GetOrderState', parameters);
};

export const obtainPrice = async (productName: Product): Promise<MappedPrice> => {
  let method;
  switch (productName) {
    case Product.Check:
      method = 'ObtainCheckPrice';
      break;
    case Product.VitD:
      method = 'ObtainVitDPrice';
      break;
    case Product.Go:
      method = 'ObtainGoPrice';
      break;
    case Product.BalanceTest:
    default:
      method = 'ObtainBalanceTestPrice';
  }
  const price = await request<Price>(`Prices/${method}`);
  return priceToMappedPrice(price);
};

export const applyCoupon = async (couponCode: string, productName: Product): Promise<MappedExtendedPrice> => {
  let method;
  switch (productName) {
    case Product.Check:
      method = 'ApplyCheckCoupon';
      break;
    case Product.VitD:
      method = 'ApplyVitDCoupon';
      break;
    case Product.BalanceTest:
    default:
      method = 'ApplyBalanceTestCoupon';
  }
  const price = await postRequest<{ couponCode: string }, ExtendedPrice>(`Prices/${method}`, { couponCode });
  return extendedPriceToMappedExtendedPrice(price);
};
