import { makeAutoObservable } from "mobx";
import { OrdersApi } from "../api/orders";
import { prepareData } from "../utils/health";

export class OrdersStore {
  order: IOrder | null = null;
  recipe: MedRecipe | null = null;
  isOrderLoading: boolean = true;
  isRecipeLoading: boolean = true;

  constructor() {
    makeAutoObservable(this);
    this.getOrders();
    this.getRecipe();
  }

  get isLoading() {
    return this.isOrderLoading || this.isRecipeLoading;
  }

  async getOrders() {
    try {
      this.isOrderLoading = true;
      const res = await OrdersApi.getOrders();
      this.order = res;
    } catch (err) {
      console.error('getOrders error', err);
      this.order = null;
    } finally {
      this.isOrderLoading = false;
    }
  }

  async getRecipe() {
    try {
      this.isRecipeLoading = true;
      const res = await OrdersApi.getRecipes();
      if (res.length) {
        this.recipe = res[0];
      }
      this.isRecipeLoading = false;
    } catch (err) {
      console.error('getRecipe error', err);
      this.recipe = null;
    } finally {
      this.isRecipeLoading = false;
    }
  }

  async addHealthData(healthForm: HealthForm) {
    try {
      if (this.order === null) {
        await this.getOrders();
        if (this.order === null) {
          return;
        }
      }
      const data: AddHealthFormRequest = prepareData(healthForm);
      await OrdersApi.addHealthData(this.order.publicId, data);
    } catch (err) {
      console.error(err);
    }
  }

  async addDeliveryData(address: Address, passport: Passport): Promise<void> {
    if (this.order === null) {
      await this.getOrders();
      if (this.order === null) {
        return;
      }
    }
    await OrdersApi.addDeliveryData(this.order.publicId, { address, passport });
    await this.getOrders();
  }
}
