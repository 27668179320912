import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { ordersStore } from '../../store';
import { OrderStatus } from '../../types/enums/orders';
import './index.css';

const Result: React.FC = observer(() => {
  const { order, recipe } = ordersStore;

  const renderResultPage = useCallback(() => {
    if (order === null) {
      return '';
    }

    if (order.status === OrderStatus.ReadyForDelivery && recipe !== null) {
      return (
        <div className="ResultTable">
          <div className="ResultTable__Head">
            <div className="ResultTable__Column">
              Элемент
            </div>
            <div className="ResultTable__Column">
              Количество
            </div>
            <div className="ResultTable__Column">
              %NRV*
            </div>
          </div>

          {recipe.micronutrients.map(nutrient => (
            <div key={nutrient.name} className="ResultTable__Row">
              <div className="ResultTable__Column">
                {nutrient.name}
              </div>
              <div className="ResultTable__Column">
                {nutrient.dailyDose.value} {nutrient.dailyDose.unit}
              </div>
              <div className="ResultTable__Column">
                {nutrient.nrvPercent ? `${nutrient.nrvPercent}%` : '**'}
              </div>
            </div>
          ))}

          <div className="ResultTable__Disclaimer">
            * — %NRV — доля от рекомендуемой суточной дозировки<br />
            ** — %NRV не определен
          </div>
        </div>
      );
    }

    return (
      <div className="ResultWait">
        <img className="ResultWait__Image" src="/images/result-success.png" alt="" />
        <div className="ResultWait__Title">Спасибо!</div>
        <div className="ResultWait__Description">
          Мы обработаем ваши ответы, наш врач проверит рекомендации дозировок.<br /><br />
          В течение 24 часов мы отправим СМС с ссылкой на ваши дозировки и форму доставки.
        </div>
      </div>
    );
  }, [order, recipe]);

  return (
    <div className="Result">
      {/* {order !== null ? order.status : 'order is null'} */}
      {renderResultPage()}
    </div>
  );
});

export default Result;
