import { LogType } from "@logdna/browser/dist/types/logdna";
// import { getGAClientId, getSnowplowId } from './analytics';
import config from "../utils/config";
import { getGAClientId, getSnowplowId } from "./analytics";

const consoleLogIntegrations: LogType[] =
  config.ENV === "LOCAL" ? [] : ["error", "log"];

if (typeof window !== "undefined") {
  
  (async (): Promise<void> => {
    const { default: logdna } = await import("@logdna/browser");
    logdna
      .init(config.LOGDNA_KEY, {
        app: `bioniq-go-${config.ENV.toLowerCase()}`,
        hostname: `logger-ui`,
        console: {
          integrations: consoleLogIntegrations,
        },
      })
      .addContext({
        env: config.ENV,
        useAgent: navigator.userAgent,
        snoplowId: getSnowplowId() || 'unknown',
        gaClientId: getGAClientId() || 'unknown',
      });
  })();
}

const log = {
  async warning(message: string, payload: object): Promise<void> {
    console.log({ message, payload });
  },
  async error(message: string, payload: object): Promise<void> {
    console.error({ message, payload });
  },
};

export default log;
