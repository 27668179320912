import axios from "axios";
import { makeAutoObservable } from "mobx";
import { AuthApi } from "../api/auth";
import { setRequestToken } from "../api/common";
import { UserApi } from "../api/user";
import { dehumanizePhone } from "../utils";
import { Storage } from "../utils/storage";

export class UserStore {
  static readonly storageKey = "token";
  user: User | null = null;
  isLoading: boolean = true;
  allowUsePersonalData: boolean = false;
  _token: string = '';

  constructor() {
    makeAutoObservable(this);
    const token = UserStore.readTokenFromStorage();
    this.token = token;
    this.getUser();
  }

  get token() {
    return this._token;
  }

  get isAuthorized() {
    return this.user !== null;
  }

  set token(value) {
    this._token = value;
    setRequestToken(value);
    this.saveToken();
    this.getUser();
  }

  async getUser() {
    try {
      this.isLoading = true;
      const user = await UserApi.getUser();
      this.user = user;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log('Axios error', err.response);
      }
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  async auth(phone: string) {
    await AuthApi.auth(dehumanizePhone(phone));
  }

  async signup(data: any) {
    await AuthApi.signup({
      ...data,
      phone: dehumanizePhone(data.phone),
    });
  }

  async verify(phone: string, code: string) {
    const res = await AuthApi.verify(dehumanizePhone(phone), code);
    this.token = res.token;
  }
  
  async logout(): Promise<void> {
    this.user = null;
    this.token = '';
  }

  static readTokenFromStorage() {
    const result = Storage.read(UserStore.storageKey);
    console.log(result);  
    return result ? result.token : '';
  }

  saveToken(): void {
    Storage.write(UserStore.storageKey, {token: this.token});
  }

  handleUnauthorizedError() {
    this.user = null;
    this.token = '';
  }

  setAllowUsePersonalData(value: boolean) {
    this.allowUsePersonalData = value;
  }
}
