import React from 'react';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadPaymentSystem } from '../../utils/payment-systems';
import CheckoutPay, { CheckoutPayProps } from './pay';

const stripePromise = loadPaymentSystem();

const CheckoutPayWrapper: React.FC<CheckoutPayProps> =
  (props) => {
    return (
      <StripeElements stripe={stripePromise}>
        <CheckoutPay {...props} />
      </StripeElements>
    );
  }

export default CheckoutPayWrapper;
