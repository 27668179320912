/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import Button from '../../components/Button';
import CodeInput from '../CodeInput';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import { calcTimeLeft, getTimeLeft } from '../../utils/login';
import ReactCodeInput from 'react-verification-code-input';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ordersStore, userStore } from '../../store';

interface VerifyProps {
  login: string;
  sentAt: Date;
  isLoggedInByEmail: boolean;
  isLoading: boolean;
  handleResend: () => void;
  handleGoBack: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

const Verify: React.FunctionComponent<VerifyProps> = observer((props) => {
  const { login, sentAt, setIsLoading, isLoading, handleGoBack, handleResend } = props;
  const [timeLeft, setTimeLeft] = useState(calcTimeLeft(sentAt));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOTPVerifyFailed, setIsOTPVerifyVailed] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => setTimeLeft(calcTimeLeft(sentAt)), 1000);
    return (): void => clearTimeout(timer);
  });

  const handleInfoClick = (): void => {
    setIsModalVisible(true);
  };
  const inputRef = useRef<ReactCodeInput>(null);

  const handleVerifyOTP = async (code: string): Promise<void> => {
    setIsLoading(true);
    try {
      await userStore.verify(login, code);
      await ordersStore.getOrders();
      await ordersStore.getRecipe();
      setIsOTPVerifyVailed(false);
      handleGoBack();
      history.push('/');
    } catch (err) {
      inputRef.current?.__clearvalues__();
      setIsOTPVerifyVailed(true);
    } finally {
      setIsLoading(false);
    }
  };
  const resendCode = () => {
    handleResend();
    setIsOTPVerifyVailed(false);
  };

  return (
    <>
      <Button onClick={(): void => handleGoBack()} back>
        Назад
      </Button>
      <div className={`Login-Container ${isLoading ? 'Login-Container--Loading' : ''}`}>
        <h4>
          Вход в личный кабинет
        </h4>
        <p className="body Login-Subtitle">
          Пожалуйста, введите одноразовый код, который был отправлен на номер <b>{login}</b>
        </p>
        <CodeInput
          ref={inputRef}
          loading={isLoading}
          onSubmit={(val: string) => {
            handleVerifyOTP(val);
          }}
        />
        {isOTPVerifyFailed && (
          <p className="Login-Error">Неверный код верификации. Проверьте, правильно ли ввели код, или запросите новый.</p>
        )}
        <p className="Login-Countdown">
          {timeLeft <= 0 ? 'Истек срок действия пароля' : `Пароль действует ${getTimeLeft(timeLeft)}`}
          <a href="#" onClick={resendCode} className="Login-Resend">Выслать заново</a>
        </p>
        {isLoading && <Loader />}
      </div>
      <a className="Login-Reg" href="#login" onClick={(e) => [e.preventDefault(), history.push('/personal')]}>Регистрация</a>
      <p onClick={handleInfoClick} className="Login-Troubles">Не получается войти?</p>
      <Modal title="Если вы не получили код" visible={isModalVisible} onClose={(): void => setIsModalVisible(false)}>
        Проверьте, правильно ли введен номер телефона.
        <br /><br />
        Запросите новый пароль, если номер неверный.
        <br /><br />
        Проверьте настройки СМС, если не получаете код. Разрешите прием сообщений с неизвестных номеров.
        <br /><br />
        В некоторых случаях доставка кода может занять до нескольких минут.
      </Modal>
    </>
  );
});

export default Verify;
