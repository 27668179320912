const getConfig = (env: string): Environment => {
  switch (env) {
    case 'development':
      return {
        BIONIQ_HUB_API_URL: 'https://hub.api.bioniq.xyz/',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_niFTWneHhpAn8HzTapLdE5gB',
        LEGACY_LEADS_API: 'https://hooks.zapier.com/hooks/catch/5295131/onet840',
        LEADS_API: 'https://bqgateway.api.bioniq.co/api',
        GTM_ID: 'GTM-5TFQ6F6',
        BALANCE_TEST_PRICE: 6990,
        CHECK_PRICE: 3499,
        GO_PRICE: 3900,
        ENV: 'DEV',
        // PRODUCTS_API: "http://localhost:3000/api",
        LOGDNA_KEY: 'e2639ea3bb9227d89317494f318dbce4',
        PRODUCTS_API: "https://products.api.bioniq.co/api",
      };
    case 'local':
      return {
        BIONIQ_HUB_API_URL: 'http://localhost:4000/',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_niFTWneHhpAn8HzTapLdE5gB',
        LEGACY_LEADS_API: 'https://hooks.zapier.com/hooks/catch/5295131/onet840',
        LEADS_API: 'https://bqgateway.api.bioniq.xyz/api',
        GTM_ID: 'GTM-5TFQ6F6',
        BALANCE_TEST_PRICE: 6990,
        CHECK_PRICE: 3499,
        GO_PRICE: 3900,
        ENV: 'LOCAL',
        LOGDNA_KEY: 'e2639ea3bb9227d89317494f318dbce4',
        PRODUCTS_API: "http://localhost:3000/api",
      };
    case 'production':
    default:
      return {
        BIONIQ_HUB_API_URL: 'https://hub.api.bioniq.com/',
        STRIPE_PUBLISHABLE_KEY: 'pk_live_hQa978LLBn4l4WfWEAxVAK67',
        LEGACY_LEADS_API: 'https://hooks.zapier.com/hooks/catch/5295131/onziyx0',
        LEADS_API: 'https://bqgateway.api.bioniq.com/api',
        GTM_ID: 'GTM-5TFQ6F6',
        BALANCE_TEST_PRICE: 6990,
        CHECK_PRICE: 3499,
        GO_PRICE: 3900,
        ENV: 'PROD',
        LOGDNA_KEY: 'e2639ea3bb9227d89317494f318dbce4',
        PRODUCTS_API: "https://products.api.bioniq.com/api",
      };
  }
};

export default getConfig(process.env.REACT_APP_ENV || process.env.NODE_ENV || '');
