import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { OrderStatus } from "../../types/enums/orders";
import config from "../../utils/config";


interface UseInitProps {
  isLoading: boolean;
  isAuthorized: boolean;
  order: IOrder | null;
  recipe: MedRecipe | null;
}

const authPathnames = new Set(['/', '/personal', '/auth', '/login']);
const readyForDeliveryPathnames = new Set(['/result', '/delivery']);
const badOrderStatuses = new Set([OrderStatus.Cancelled, OrderStatus.FailedPayment, OrderStatus.AwaitingPayment]);
const resultPageOrderStatuses = new Set([OrderStatus.MedProcessing, OrderStatus.ReadyForDelivery]);
const statusPageOrderStatuses = new Set([OrderStatus.Delivery, OrderStatus.Complete]);

const useInit = (props: UseInitProps) => {
  const history = useHistory();
  const location = useLocation();

  const { isLoading, isAuthorized, order, recipe } = props;

  const navigate = useCallback((path) => {
    if (location.pathname !== path) {
      history.replace(path);
    }
  }, [history, location]);

  useEffect(() => {
    if (config.ENV === 'LOCAL') {
      return;
    }
    if (isLoading) {
      return;
    }

    if (!isAuthorized) {
      if (!authPathnames.has(location.pathname)) {
        navigate('/login');
      }
      return;
    }

    if (order === null || badOrderStatuses.has(order.status)) {
      navigate('/pay');
      return;
    }

    if (order.status === OrderStatus.Paid) {
      navigate('/health');
      return;
    }

    if (resultPageOrderStatuses.has(order.status) && !readyForDeliveryPathnames.has(location.pathname)) {
      navigate('/result');
      return;
    }

    if (statusPageOrderStatuses.has(order.status)) {
      navigate('/status');
      return;
    }
  }, [isLoading, isAuthorized, order, recipe, history, location, navigate]);
}

export default useInit;
