import * as React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './index.css';

interface LoaderProps {
  full?: boolean;
}

const Loader: React.FunctionComponent<LoaderProps> = (props) => {
  const { full = true } = props;

  return (
    <section className={`Loader ${full ? 'Loader--Full' : ''}`}>
      <div className="Loader-Inner">
        <LoadingOutlined style={{ fontSize: 32 }} spin />
      </div>
    </section>
  );
};

export default Loader;
