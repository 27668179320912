import * as React from 'react';
import './index.css';
import { ReactComponent as CheckedIcon } from './checked.svg';

interface CheckboxProps {
  checked: boolean;
  label?: JSX.Element;
  onClick: (value: boolean) => void;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = (props: CheckboxProps) => {
  const { checked, label, onClick } = props;

  const className = checked === true ? 'Checkbox Checkbox--Checked' : 'Checkbox';

  return (
    <div className={className} onClick={(): void => onClick(!checked)}>
      <div className="Checkbox-Inner">
        <CheckedIcon />
      </div>
      {label && <span className="Checkbox-Label">{label}</span>}
    </div>
  );
};

export default Checkbox;
