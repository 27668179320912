import { Radio as AntdRadio, Form } from 'antd';
import React from 'react';
import './index.css';

interface RadioProps {
  className?: string;
  name?: string;
  value: string;
  vertical?: boolean;
  answers: AnswerOption[];
  onChange: (value: string) => void;
}

const Radio: React.FunctionComponent<RadioProps> = (props) => {
  const { value, vertical, className = '', onChange, answers, name } = props;

  return (
    <Form.Item name={name} rules={[{ required: true, message: 'Please select your country!' }]}>
      <AntdRadio.Group
        value={value}
        className={`HealthForm-Radio ${vertical ? 'Vertical' : ''}${className}`}
        onChange={(e): void => onChange(e.target.value)}
      >
        {answers.map((option) => {
          return (
            <AntdRadio.Button
              key={option.answerPositionId}
              className="HealthForm-RadioBtn"
              value={option.answerPositionId}
            >
              {option.text}
            </AntdRadio.Button>
          );
        })}
      </AntdRadio.Group>
    </Form.Item>
  );
};

export default Radio;
