import { makeAutoObservable } from 'mobx';
import { priceStore } from '.';
import { Product } from '../utils';

export class ProductStore {
  product: Product = Product.Go;

  constructor() {
    makeAutoObservable(this);
  }

  async setProduct(product: Product): Promise<void> {
    this.product = product;
    await priceStore.getPrices(product);
  }

  get isSubscription(): boolean {
    return this.product === Product.Balance;
  }
}
