import axios from 'axios';
import config from '../utils/config';
import { UserStore } from '../store/user';
import log from '../utils/log';

const _request = axios.create({ baseURL: config.PRODUCTS_API, headers: { Authorization: `Bearer ${UserStore.readTokenFromStorage()}`} });

export const setRequestToken = (token: string) => _request.defaults.headers.Authorization = `Bearer ${token}`;

interface GetParameter {
  readonly name: string;
  readonly value: string | number | boolean;
}

export async function request<TResponse>(methodPath: string, parameters: GetParameter[] = []): Promise<TResponse> {
  const query = parameters.length > 0 ? `?${parameters.map((x) => `${x.name}=${x.value}`).join('&')}` : '';
  const path = `${methodPath}${query}`;
  const response = await _request.get<TResponse>(path, );
  return response.data;
}

export async function postRequest<TRequest, TResponse>(
  methodPath: string,
  requestBody: TRequest,
  baseUrl?: string
): Promise<TResponse> {
  try {
    const path = `${baseUrl ? baseUrl : config.PRODUCTS_API}${methodPath}`;
    const response = await _request.post<TResponse>(path, requestBody);

    return response.data;
  } catch (err) {
    log.error('POST Request error', {
      methodPath,
      requestBody,
      error: { ...(err as object) },
    });
    throw err;
  }
}

export class HttpError<T = any> extends Error {
  readonly code: number;
  readonly response: T;

  public constructor(code: number, response: T) {
    super('Unknown error');
    this.code = code;
    this.response = response;
  }
}
