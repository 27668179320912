import { format as _format } from "date-fns";
import { ru } from "date-fns/locale";

export function humanizeDate(raw: string | Date): string {
  const date = typeof raw === "string" ? new Date(raw) : raw;
  const [day, month, year] = [
    date.getDate(),
    date.getMonth(),
    date.getFullYear(),
  ];
  return [
    day.toString().padStart(2, "0"),
    (month + 1).toString().padStart(2, "0"),
    year.toString(),
  ].join(".");
}

export function humanDateToISO(raw: string): string {
  const [d, m, y] = raw.split(".");
  return [y, m, d].join("-");
}

interface FormatOptions {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

export const pluralizeClinic = (num: number): string => {
  const options = ["клиникy", "клиники", "клиник"];
  return pluralize(num, options);
};

export const pluralize = (count: number, words: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return count === undefined
    ? count
    : words[
        count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
      ];
};

export default function format(
  date: number | Date,
  format: string,
  options?: FormatOptions
): string {
  return _format(date, format, {
    locale: ru,
    ...options,
  });
}
