export const formatPrice = (value: number): string =>
  new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    useGrouping: true,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);

export const priceForVendor = (price: MappedPrice, vendor: VendorType): number => {
  if (Object.prototype.hasOwnProperty.call(price.vendorPrices, vendor)) {
    return price.vendorPrices[vendor].amount;
  }
  return price.amount;
};

export const formatPriceForVendor = (price: MappedPrice, vendor: VendorType): string =>
  formatPrice(priceForVendor(price, vendor));

export const mappedExtendedPriceToMappedPrice = (price: MappedExtendedPrice): MappedPrice => ({
  amount: price.fullAmount,
  vendorPrices: price.vendorPrices,
  currencySign: price.currencySign,
});

export const vendorPricesToMappedVendorPrices = (vendorPrices: VendorPrice[]): MappedVendorPrices => {
  const obj: MappedVendorPrices = {};
  vendorPrices.forEach((price) => {
    obj[price.labOfficeVendor] = price;
  });
  return obj;
};

export const priceToMappedPrice = (price: Price): MappedPrice => ({
  amount: price.amount,
  currencySign: price.currencySign,
  vendorPrices: vendorPricesToMappedVendorPrices(price.vendorPrices),
});

export const extendedPriceToMappedExtendedPrice = (price: ExtendedPrice): MappedExtendedPrice => ({
  amount: price.amount,
  fullAmount: price.fullAmount,
  currencySign: price.currencySign,
  vendorPrices: vendorPricesToMappedVendorPrices(price.vendorPrices),
});
