import React from 'react';
import { options } from '../../../store/pay';
import { PayOption } from '../../../types/enums/options';
import Button from '../../Button';

interface PayOptionsProps {
  onChoose: (value: PayOption) => void;
}

const PayOptions: React.FC<PayOptionsProps> = ({ onChoose }) => {
  return (
    <div className="PayOptions">
      {options.map((item) => (
        <div className="PayOptionItem" key={item.value}>
          <div className="PayOptionItem-Content">
            <p className="PayOptionItem-Title"><img src="/images/bioniq-go-logo-black.svg" alt="bioniq" />{item.title}</p>
            {item.description && <p className="PayOptionItem-Description">{item.description}</p>}
            {item.freeDelivery && <p className="PayOptionItem-Description">Доставка бесплатно</p>}
            <p className="PayOption-Price">{item.price}&nbsp;₽</p>
          </div>
          <Button small form onClick={() => onChoose(item.value)}>Выбрать</Button>
        </div>
      ))}
    </div>
  );
}


export default PayOptions;
