export const emptyPersonalInfo: Personal = {
  givenName: '',
  familyName: '',
  fatherName: '',
  phone: '',
  email: '',
};

export const emptyAdditionalInfo: Additional = {
  fatherName: '',
  birthDate: '',
  gender: '',
};

export const emptyHealthInfo: HealthForm = {
  fatherName: '',
  birthDate: '',
  gender: '',
  height: '',
  weight: '',
  lifestyle: '',
  allergy: [],
  sickness: [],
  meds: [],
  diet: '',
  pregnancy: '',
  vits: '',
  vitsQuantity: '',
  recs: [],
  goals: [],
};

export const emptyCardData: CardData = {
  cardNumber: '',
  expDate: '',
  expDateMonth: '',
  expDateYear: '',
  cvv: '',
};

export const emptyAddressInfo: Address = { country: '', city: '', addressLine: '', zip: '' };

export const emptyPassportData: Passport = { number: '', issueDate: '', issuedBy: '', INN: '' };
