import { DeliveryStore } from "./delivery";
import { OrdersStore } from "./order";
import { PayStore } from "./pay";
import { PriceStore } from "./price";
import { ProductStore } from "./product";
import { UserStore } from "./user";

export const userStore = new UserStore();
export const deliveryStore = new DeliveryStore();
export const priceStore = new PriceStore();
export const productStore = new ProductStore();
export const ordersStore = new OrdersStore();
export const payStore = new PayStore()
