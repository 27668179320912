import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { CardCvcElement, CardExpiryElement, CardNumberElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';
import './index.css';
import Loader from '../Loader';
import { ordersStore, payStore, userStore } from '../../store';
import { PaymentApi } from '../../api/payment';
import PayOptions from './PayOptions';
import { PayOption } from '../../types/enums/options';
import { PaymentRequest as StripePR } from '@stripe/stripe-js';

export interface CheckoutPayProps {
  validateForm: (val: boolean) => void;
  isError: boolean;
}

const cardInputClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};

const cardInputStyles = {
  base: {
    fontSize: '16px',
    fontWeight: '300',
  },
};

const Card: React.FC<{ isLoading: boolean }> = ({ isLoading }) => (
  <div className={`FormCard ${isLoading ? 'FormCard--Loading' : ''}`}>
    <div className="CheckoutPayCard">
      <div className="CheckoutPayCard-Title">Оплата картой</div>
      <div className="CheckoutPayCard-Input CheckoutPayCard-Input--Number">
        <CardNumberElement options={{ classes: cardInputClasses, style: cardInputStyles }} />
        <label className="CheckoutPayCard-Label">Номер карты</label>
      </div>
      <div className="CheckoutPayCard-Input CheckoutPayCard-Input--Expiry">
        <CardExpiryElement options={{ classes: cardInputClasses, style: cardInputStyles }} />
        <label className="CheckoutPayCard-Label">Срок действия</label>
      </div>
      <div className="CheckoutPayCard-Input CheckoutPayCard-Input--Cvc">
        <CardCvcElement options={{ classes: cardInputClasses, style: cardInputStyles }} />
        <label className="CheckoutPayCard-Label">CVC/CVV код</label>
      </div>
    </div>
  </div>
);

const CheckoutPay: React.FC<CheckoutPayProps> = observer(
  ({ isError: _isError }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const { option, currentOption } = payStore;
    const { user } = userStore;

    const history = useHistory();

    const elements = useElements();
    const stripe = useStripe();

    const handlePay = async (paymentMethodId: string): Promise<boolean> => {
      if (isLoading || option === null) {
        return false;
      }
      setIsError(false);
      setIsLoading(true);
      if (stripe === null || elements === null) {
        console.error('Stripe or elements is null');
        return false;
      }
      if (user === null) {
        history.push('/');
        return false;
      };
      let pmId = paymentMethodId;
      if (!pmId) {
        const pmResult = await stripe.createPaymentMethod({
          type: 'card',
          card: elements!.getElement(CardNumberElement)!,
          billing_details: {
            name: `${user.familyName} ${user.givenName}`,
            email: user.email,
            phone: user.phone,
          },
        });
  
        if (pmResult.error) {
          console.log('createPaymenthMethod error', pmResult.error);
          return false;
        }
        pmId = pmResult.paymentMethod.id;
      }

      try {
        const response = await PaymentApi.purchaseGo({ paymentMethodId: pmId, option });

        if ('error' in response) {
          // Show error from server on payment form
          console.log('purchase go error', response);
        } else if ('requiresAction' in response) {
          // Use Stripe.js to handle required card action
          const result = await stripe.handleCardAction(response.clientSecret);

          if (result.error) {
            console.log('handleCardAction error', result);
            setIsError(true);
            return false;
          }
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          const verifyResponse = await PaymentApi.verifyGoPurchase({ paymentIntentId: result.paymentIntent.id });

          console.log({ verifyResponse });

          await ordersStore.getOrders();
        } else {
          // Show success message
          console.log('purchase go payment success');
          return true;
        }
      } catch (err) {
        console.error('payment error', err);
        setIsError(true);
      }
      setIsLoading(false);
      return false;
    }

    (window as any).handlePay = handlePay;

    const [paymentRequest, setPaymentRequest] = useState<StripePR | null>(null);

    useEffect(() => {
      (async () => {
        if (!stripe || !currentOption) {
          return;
        }
        const pr = stripe.paymentRequest({
          country: 'GB',
          currency: 'rub',
          total: {
            label: 'bioniq GO',
            amount: currentOption.price * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
        const result = await pr.canMakePayment();
        if (result) {
          setPaymentRequest(pr);
        }
        pr.on('paymentmethod', async (ev) => {
          const result = await handlePay(ev.paymentMethod.id);
          if (result) {
            ev.complete('success');
            await ordersStore.getOrders();
            history.push('/health');
          } else {
            ev.complete('fail');
          }
        });
      })();
    }, [stripe]);

    useEffect(() => {
      if (!paymentRequest || !currentOption) {
        return;
      }
      paymentRequest.update({
        total: {
          label: 'bioniq GO',
          amount: currentOption.price * 100,
        }
      })
    }, [currentOption]);

    const styles = {
      '--icon-mastercard': `url(/images/mastercard.svg)`,
      '--icon-visa': `url(/images/visa.svg)`,
      '--icon-maestro': `url(/images/maestro.svg)`,
      '--icon-mir': `url(/images/mir.svg)`,
    } as React.CSSProperties;

    const handlePayOptionChoose = (payOption: PayOption | null) => {
      payStore.option = payOption;
    }

    return (
      <div className="CheckoutPay" style={styles}>
        {isLoading && <Loader />}
        {currentOption ? (
          <>
            <div className="CurrentOption-Wrapper">
              <p className="CurrentOption-Title">Ваш заказ</p>
              <div className="CurrentOption" onClick={(e) => [e.preventDefault(), handlePayOptionChoose(null)]}>
                <img src="/images/bioniq-go-logo-black.svg" className="CurrentOption-Logo" alt="bioniq" />
                <span className="CurrentOption-Name">{currentOption.title}</span>
                <span className="CurrentOption-Price">({currentOption.price}&nbsp;₽)</span>
                <a href="#option" className="CurrentOption-Change">Изменить</a>
              </div>
            </div>
            {paymentRequest && <PaymentRequestButtonElement className="CheckoutPay-PRButtons" options={{paymentRequest}} />}
            <Card isLoading={isLoading} />
            {isError && <div className="CheckoutPay-Error">Ошибка оплаты</div>}
          </>
        ) : <PayOptions onChoose={handlePayOptionChoose} />
        }
      </div>
    );
  }
);


export default CheckoutPay;
