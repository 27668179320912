const EXPIRE_IN = 10; // minutes
export const calcTimeLeft = (from: Date): number => {
  const diff = (EXPIRE_IN * 1000 * 60) - (+new Date() - +from);
  return Math.floor((diff / 1000));
}

export const getTimeLeft = (secs: number): string => {
  const minutes = Math.floor(secs / 60 % 60);
  const seconds = Math.floor(secs % 60);
  return [minutes, seconds].map((i) => i.toString().padStart(2, '0')).join(':');
}
