import React from 'react';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import Checkout from '../Checkout';
import Header from '../Header';
import Login from '../Login';
import OrderStatus from '../OrderStatus';
import './index.css';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="CheckoutPage">
          <Switch>
            <Route path="/status">
              <OrderStatus />
            </Route>
            <Route path="/login">
              <Header />
              <Login />
            </Route>
            <Route path="/">
              <Header />
              <Checkout sections={['personal', 'auth', 'pay', 'health', 'result', 'delivery']} />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
