import { Checkbox, Form } from 'antd';
import React from 'react';
import './index.css';

interface MultipleSelectProps {
  className?: string;
  name?: string;
  value: string[];
  answers: AnswerOption[];
  onChange: (value: string) => void;
}

const MultipleSelect: React.FunctionComponent<MultipleSelectProps> = (props) => {
  const { value, className = '', onChange, answers, name } = props;

  return (
    <Form.Item
      name={name}
      className={`MultipleSelect ${className}`}
      rules={[{ required: true, message: 'Выберите вариант' }]}
    >
      {answers.map((option) => {
        return (
          <Checkbox
            key={option.answerPositionId}
            value={option.answerPositionId}
            onChange={(e): void => onChange(e.target.value)}
            checked={value.includes(option.answerPositionId)}
          >
            {option.text}
          </Checkbox>
        );
      })}
    </Form.Item>
  );
};

export default MultipleSelect;
