import Analytics, { AnalyticsInstance } from 'analytics';
// import snowplowPlugin from '@analytics/snowplow';
import config from './config';
import { Product, toCamelCase, toSnakeCase } from '.';

let analytics: AnalyticsInstance;

declare global {
  interface Window {
    dataLayer: any;
  }
}

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
  getAnalytics();
}

function getAnalytics(): AnalyticsInstance {
  if (typeof analytics === 'undefined') {
    analytics = Analytics({
      app: 'bioniq-app',
      plugins: [
        // snowplowPlugin({
        //   name: 'snowplow',
        //   scriptSrc: '/scripts/snowplow-tracker.js',
        //   collectorUrl: 'bianka.bioniq.com',
        //   trackerSettings: {
        //     appId: 'com.bioniq',
        //     contexts: {
        //       webPage: true,
        //       performanceTiming: true,
        //     },
        //   },
        //   protocol: 'https',
        //   method: 'post',
        // }),
        // googleTagManager({
        //   containerId: config.GTM_ID,
        //   customScriptSrc: 'https://collector.bioniq-medsi.ru/gtm.js'
        // }),
      ],
    });
  }
  return analytics;
}

export function gtmPushSuccessPay(data: PurchaseInfo): void {
  const { orderId, price, product } = data;

  const ecommerce = {
    currencyCode: 'RUB', // передаем валюту
    purchase: {
      actionField: {
        id: orderId, // id Транзакции
        revenue: price, // стоимость транзакции
      },
      products: [
        {
          name: product, // название товара
          id: product, // id товара
          price: price, // цена за 1 ед товара
          variant: 1, // тип товара
          quantity: 1, // количество ед товара
        },
      ],
    },
  };
  // getAnalytics().track('transaction', { ecommerce });

  window.dataLayer.push({
    event: 'transaction',
    ecommerce,
  });
}

export function snowplowAddItem(data: PurchaseInfo): void {
  const { orderId, price, product } = data;

  const item = {
    orderId,
    SKU: product,
    name: product,
    category: 'Test',
    price: price,
    quantity: 1,
    currency: 'RUB',
  };
  getAnalytics().track('addItem', item);
}

export function snowplowAddTransaction(orderId: string, price: number): void {
  const data = {
    orderId,
    affiliation: null,
    total: price,
    tax: null,
    shipping: null,
    city: null,
    state: null,
    country: null,
    currency: 'RUB',
  };
  getAnalytics().track('addTrans', data);
}

export function sendFormBeforePay(product: Product): void {
  // getAnalytics().track('send_form', {
  //  formName: 'medsi-before-pay',
  //  formLocation: 'medsi-pay',
  // });
  if (config.ENV === 'PROD') {
    window.dataLayer.push({
      event: 'send_form',
      formName: 'medsi-before-pay',
      formLocation: 'medsi-pay',
      product: getProductName(product),
    });
  }
}

export function sendFormSuccessPay(): void {
  // getAnalytics().track('send_form', {
  //   formName: 'medsi-success-pay',
  //   formLocation: 'medsi-pay',
  // });
  window.dataLayer.push({
    event: 'send_form',
    formName: 'medsi-success-pay',
    formLocation: 'medsi-pay',
  });
}

export function getSnowplowId(): string | undefined {
  const matcher = new RegExp('_sp_id\\.[a-f0-9]+=([^;]+);?');
  const match = document.cookie.match(matcher);
  if (match && match[1]) {
    return match[1].split('.')[0];
  }
}

export function getGAClientId(): string | undefined {
  const matches = document.cookie.match(
    // eslint-disable-next-line
    new RegExp('(?:^|; )' + '_ga'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  if (matches && matches[1]) {
    const tmp = matches[1].split('.');
    return tmp[2] + '.' + tmp[3];
  }
}

function getGASessionId(): string | undefined {
  const gaId = 'VB3MNC1DW4';
  const matches = document.cookie.match(
    // eslint-disable-next-line
    new RegExp('(?:^|; )' + `_ga_${gaId}`.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  if (matches && matches[1]) {
    const tmp = matches[1].split('.');
    return tmp[2];
  }
}

export class UtmMarks {
  static List = ['utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'utm_medium'];
  static StorageKey = 'utm-marks';

  static parseFromUrl(): void {
    const paramsString = window.location.search.substr(1).split('&');
    if (paramsString.length === 0) {
      return;
    }

    const params = UtmMarks.get();
    paramsString.forEach((param) => {
      const [key, value] = param.split('=');
      if (UtmMarks.List.includes(key) && value.length) {
        params[toCamelCase(key)] = value;
      }
    });
    sessionStorage.setItem(UtmMarks.StorageKey, JSON.stringify(params));
  }

  static get(): UtmValues {
    const rawMarks = sessionStorage.getItem(UtmMarks.StorageKey);
    return rawMarks !== null ? JSON.parse(rawMarks) : {};
  }
}

export const collectAnalytics = (): Analytics => {
  const snowplowId = getSnowplowId();
  const utmMarks = UtmMarks.get();
  const gaClientId = getGAClientId();
  const gaSessionId = getGASessionId();

  const [path] = window.location.href.split('?');
  const page = `${path}?${Object.entries(utmMarks)
    .map(([key, value]) => `${toSnakeCase(key)}=${value}`)
    .join('&')}`;

  return {
    ...utmMarks,
    snowplowId,
    gaClientId,
    gaSessionId,
    page,
  };
};

const steps = [
  'personal_data',
  'life_style',
  'sports',
  'allergies',
  'diseases',
  'drugs',
  'nutrition',
  'data_processing',
];

export const pushToDataLayer = (section: string, step: number): void => {
  let action;
  let funnelStep;
  switch (section) {
    case 'personal':
      sendFormBeforePay(Product.Go);

      action = 'create_lead';
      funnelStep = '2';
      break;
    case 'health':
      action = step === 8 ? 'health_form_confirm' : `fill_${steps[step]}`;
      funnelStep = step === 8 ? '5' : `4.${step}`;
      break;
  }

  // getAnalytics().track('productFunnel', {
  //   eventData: {
  //     product,
  //     action,
  //     funnelStep,
  //     funnelVersion: 'autoflow',
  //   },
  // });
  window.dataLayer.push({
    event: 'productFunnel',
    eventData: {
      product: getProductName(Product.Go),
      action,
      funnelStep,
      funnelVersion: 'autoflow',
    },
  });
};

const getProductName = (product: Product): string => {
  switch (product) {
    case Product.BalanceTest:
      return 'RU BALANCE Test';
    case Product.Check:
      return 'RU CHECK Test';
    case Product.Balance:
      return 'RU BALANCE Subscription';
    case Product.Go:
      return 'RU Go Test';
    default:
      return product;
  }
};
